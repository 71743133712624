import { ProductionRun } from './production-run.model';

export interface ConnectedScale {
  id: string;
  name: string;
}

export interface DeviceStatus {
  id: string;
  scaleMode: WeighingMode;
  lastUpdate: Date;
  error: any;
  status: boolean;
  scaleUnloaded: boolean;
  productionRunCheckExecutionId?: string | null;
  productionRun?: ProductionRun;
}

export interface Device {
  id: string;
  name: string;
  deviceType: DeviceType;
  // more properties are not needed yet
}

export interface VirtualDevice extends Device {}

export interface Scale extends Device {
  weighingMode: WeighingMode;
  deviceId?: string;
}

export enum DeviceType {
  Scale = 1,
  Virtual = 2,
}

export enum WeighingMode {
  Automatic = 1,
  Manual = 2,
}
