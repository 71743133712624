import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWeighingCategory, WeighingCategory } from '../../../helper';
import { CheckAttributeType, ProductionRunStatus, TareMode, WeighingMode } from '../../../model';
import {
  automaticWeighingErrorsSelector,
  checkExecutionIssuesWebsocketConfig,
  checkExecutionsWebsocketConfig,
  competingOrdersDialogOpenSelector,
  devicesWebsocketConfig,
  executeCheckWeight,
  fetchCheckWeightSamples,
  getHeartbeat,
  hasAllRequiredCheckWeightsSelector,
  hasAllRequiredTareWeightsSelector,
  initCheckWeights,
  resetCheckWeightState,
  scalesStatusSelector,
  setIdentificator,
  setNotification,
  startWsConnection,
  unsetStaticScaleDevice,
} from '../../../store';
import { CheckProps } from '../check.component';
import { FertigPackVCheckComponent } from '../fertigpackv-check/fertigpackv-check.component';
import { VolumeCheckComponent } from '../volume-check/volume-check.component';
import { WeightCheckComponent } from '../weight-check/weight-check.component';

export const WeighingComponent = (checkProps: CheckProps) => {
  const dispatch = useDispatch();
  const { productionRun, openCheckExecution, checkAttribute, isDisruptionDialogOpen } = checkProps;
  const hasAllRequiredTareWeights = useSelector(hasAllRequiredTareWeightsSelector);
  const automaticWeighingError = useSelector(automaticWeighingErrorsSelector);
  const scalesStatus = useSelector(scalesStatusSelector);
  const hasAllRequiredCheckWeights = useSelector(hasAllRequiredCheckWeightsSelector);
  const hasBlockedOrder = useSelector(competingOrdersDialogOpenSelector);

  const { sampleSize, checkAttributeType } = checkAttribute;
  const weighingCategory = getWeighingCategory(checkAttributeType);
  const { id: productionRunId, article, scaleDevice } = productionRun;
  const { productionRunCheckExecutionId: checkExecutionId } = openCheckExecution;
  const scaleId = scaleDevice?.id;
  const isAutomaticWeighing = productionRun.scaleDevice?.weighingMode === WeighingMode.Automatic;
  const currentAutomaticWeighingError =
    automaticWeighingError && automaticWeighingError[productionRunId];
  const deviceId = scaleDevice?.deviceId;
  const currentScaleStatus = deviceId ? scalesStatus[deviceId] : undefined;

  const checkComplete =
    hasAllRequiredCheckWeights &&
    (hasAllRequiredTareWeights ||
      checkAttribute.checkAttributeType === CheckAttributeType.Volume ||
      checkAttributeType === CheckAttributeType.Weight ||
      article.tareMode !== TareMode.IndividualTare);

  const shouldSetNewCheckId =
    (currentScaleStatus?.productionRun &&
      currentScaleStatus.productionRun.id === productionRunId &&
      currentScaleStatus.productionRunCheckExecutionId !== checkExecutionId) ||
    !currentScaleStatus?.productionRun;

  const nextCheck =
    productionRun.openCheckExecutions.length > 1 && productionRun.openCheckExecutions[1];

  const shouldUnsetScaleAferCheck =
    !nextCheck ||
    (nextCheck.productionRunCheckCheckAttributeType !== CheckAttributeType.FertigPackV &&
      nextCheck.productionRunCheckCheckAttributeType !== CheckAttributeType.Tare &&
      nextCheck.productionRunCheckCheckAttributeType !== CheckAttributeType.TestRun &&
      nextCheck.productionRunCheckCheckAttributeType !== CheckAttributeType.Volume &&
      nextCheck.productionRunCheckCheckAttributeType !== CheckAttributeType.Weight);

  const shouldUnsetScaleWhenPaused =
    currentScaleStatus?.productionRun &&
    currentScaleStatus.productionRun.id === productionRunId &&
    currentScaleStatus.productionRunCheckExecutionId === checkExecutionId &&
    productionRun.status === ProductionRunStatus.Paused;

  useEffect(() => {
    dispatch(startWsConnection(checkExecutionsWebsocketConfig));
    dispatch(startWsConnection(checkExecutionIssuesWebsocketConfig));
    dispatch(startWsConnection(devicesWebsocketConfig));
    return () => {
      shouldUnsetScaleAferCheck && dispatch(unsetStaticScaleDevice(productionRunId));
    };
  }, [checkExecutionId, dispatch, productionRunId, shouldUnsetScaleAferCheck]);

  useEffect(() => {
    scaleId && dispatch(getHeartbeat(scaleId));
    const interval = setInterval(() => {
      scaleId && !checkComplete && dispatch(getHeartbeat(scaleId));
    }, 10000);
    return () => clearInterval(interval);
  }, [checkComplete, dispatch, scaleId]);

  useEffect(() => {
    dispatch(initCheckWeights(sampleSize));
  }, [dispatch, sampleSize, checkExecutionId, isDisruptionDialogOpen]);

  useEffect(() => {
    if (
      shouldSetNewCheckId &&
      !hasBlockedOrder &&
      productionRun.status !== ProductionRunStatus.Paused
    ) {
      dispatch(setIdentificator(productionRunId, checkExecutionId));
    }
  }, [
    shouldSetNewCheckId,
    dispatch,
    productionRunId,
    checkExecutionId,
    hasBlockedOrder,
    productionRun.status,
  ]);

  useEffect(() => {
    if (shouldUnsetScaleWhenPaused) {
      dispatch(unsetStaticScaleDevice(productionRunId));
    }
  }, [shouldUnsetScaleWhenPaused, dispatch, productionRunId]);

  useEffect(() => {
    dispatch(fetchCheckWeightSamples(productionRunId, checkExecutionId));
    return () => {
      dispatch(resetCheckWeightState());
    };
  }, [dispatch, productionRunId, checkExecutionId]);

  useEffect(() => {
    if (isAutomaticWeighing && currentAutomaticWeighingError) {
      dispatch(setNotification(currentAutomaticWeighingError, productionRunId));
    }
  }, [isAutomaticWeighing, currentAutomaticWeighingError, dispatch, productionRunId]);

  const executeMeasurement = (sampleIndex: number, forTare: boolean) => {
    const sampleNumber = sampleIndex + 1;
    productionRun.scaleDevice &&
      dispatch(
        executeCheckWeight(
          sampleNumber,
          productionRun.id,
          checkExecutionId,
          productionRun.scaleDevice.id,
          forTare
        )
      );
  };

  switch (weighingCategory) {
    case WeighingCategory.FertigPackV:
      return (
        <FertigPackVCheckComponent
          checkProps={checkProps}
          executeMeasurement={executeMeasurement}
        />
      );
    case WeighingCategory.Weight:
      return (
        <WeightCheckComponent checkProps={checkProps} executeMeasurement={executeMeasurement} />
      );
    case WeighingCategory.Volume:
      return (
        <VolumeCheckComponent checkProps={checkProps} executeMeasurement={executeMeasurement} />
      );
    default:
      return <></>;
  }
};
