import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { getFertigPackVStatistics, getWeightSuffix } from '../../helper';
import { ProductionRun } from '../../model';
import { FormattedNumberOutputComponent } from '../formatted-number-output/formatted-number-output.component';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';

import {
  FlexEndContainer,
  GiveAwayContainer,
  GiveAwayGoldBox,
  GiveAwayWhiteBox,
  ZeroPoint,
} from './give-away-chart.styles';

export interface OwnProps {
  productionRun: ProductionRun;
}

export const GiveAwayChartComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const statistics = getFertigPackVStatistics(productionRun);
  const checkSamplesGiveAwayAbsolute = statistics?.checkSamplesGiveAwayAbsolute;
  const checkSamplesGiveAwayRelative = statistics?.checkSamplesGiveAwayRelative;
  const checkSamplesWeightsAbsolute = statistics?.checkSamplesWeightsAbsolute;
  const checkSamplesDone = statistics?.checkSamplesDone;
  const [goldBoxWidth, setGoldBoxWidth] = useState<number>(
    checkSamplesGiveAwayRelative ? Math.min(checkSamplesGiveAwayRelative, 100) : 0
  );
  const [whiteBoxWidth, setWhiteBoxWidth] = useState<number>(100 - goldBoxWidth);
  const suffix = getWeightSuffix(productionRun.article.measurementUnit);

  const shouldShowZeroPoint =
    goldBoxWidth > 0 && checkSamplesGiveAwayAbsolute && checkSamplesGiveAwayAbsolute < 0;

  useEffect(() => {
    if (
      checkSamplesWeightsAbsolute &&
      checkSamplesGiveAwayAbsolute &&
      checkSamplesGiveAwayAbsolute < 0
    ) {
      const diagramWidth = checkSamplesGiveAwayAbsolute * -1 + checkSamplesWeightsAbsolute;
      setGoldBoxWidth(((checkSamplesGiveAwayAbsolute * -1) / diagramWidth) * 100);
      setWhiteBoxWidth((checkSamplesWeightsAbsolute / diagramWidth) * 100);
    }
  }, [checkSamplesGiveAwayAbsolute, checkSamplesWeightsAbsolute]);

  return (
    <>
      {checkSamplesDone && (
        <GridContainer level={GridLevel.Collective} data-testid="giveAwayChartContainer">
          <GridItem>
            <GiveAwayContainer>
              {goldBoxWidth > 0 && (
                <GiveAwayGoldBox
                  styleProps={{
                    percentage: goldBoxWidth,
                  }}
                  data-testid="giveAwayGoldBox"
                />
              )}
              {shouldShowZeroPoint && <ZeroPoint data-testid="giveAwayZeroPoint" />}
              {whiteBoxWidth > 0 && (
                <GiveAwayWhiteBox
                  styleProps={{
                    percentage: whiteBoxWidth,
                  }}
                  data-testid="giveAwayWhiteBox"
                />
              )}
            </GiveAwayContainer>
          </GridItem>
          <GridItem s={6}>
            <Typography
              variant="h5"
              marginTop={
                checkSamplesGiveAwayAbsolute !== undefined && checkSamplesGiveAwayAbsolute >= 0
                  ? '12px'
                  : 0
              }
            >
              <FormattedNumberOutputComponent
                value={checkSamplesGiveAwayAbsolute}
                decimalScale={3}
                suffix={suffix}
              />
            </Typography>
          </GridItem>
          <GridItem s={6}>
            <FlexEndContainer>
              <Typography
                variant="h5"
                marginTop={
                  checkSamplesGiveAwayAbsolute !== undefined && checkSamplesGiveAwayAbsolute >= 0
                    ? '12px'
                    : 0
                }
              >
                <FormattedNumberOutputComponent
                  value={checkSamplesWeightsAbsolute}
                  decimalScale={3}
                  suffix={suffix}
                />
              </Typography>
            </FlexEndContainer>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};
