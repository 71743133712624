import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { irisSpacing } from '../../theme';

export const CheckInfoText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(irisSpacing.h3.mb),
}));

export const InfoHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
