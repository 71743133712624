import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TEMPERATURE_ENTITY } from '../../../constants';
import { CheckAttributeType } from '../../../model';
import { confirmCheckForProductionRun } from '../../../store';
import { irisSpacing } from '../../../theme';
import { CheckAccordion } from '../../accordion/check-accordion.component';
import { FormattedSampleInputComponent } from '../../formatted-sample-input/formatted-sample-input.component';
import { GridContainer, GridLevel } from '../../grid/grid.container';
import { GridItem } from '../../grid/grid.item';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { ContainerInside, InfoHeaderContainer } from '../../structure';
import { CheckProps } from '../check.component';
import {
  CheckConfirmationButtonComponent,
  CheckDescriptionComponent,
  CheckInputContainer,
} from '../common/check.components';

const SamplesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(irisSpacing.input.space),
}));

const InputItem = styled('div')(({ theme }) => ({
  width: 'inherit',
  maxWidth: 138,
}));

export const TemperatureCheckComponent = (props: CheckProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const { productionRun, openCheckExecution, isDisruptionDialogOpen, checkAttribute } = props;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [currentTemperatures, setCurrentTemperatures] = useState<{
    [id: number]: string | undefined;
  }>({});
  const [hasAllRequiredTemperatures, setHasAllRequiredTemperatures] = useState<boolean>(false);
  const [hasValidTemperatures, setHasValidTemperatures] = useState<boolean>(false);

  const { sampleSize } = checkAttribute;

  useEffect(() => {
    setSubmitted(false);
    setCurrentTemperatures({});
    setHasAllRequiredTemperatures(false);
    setHasValidTemperatures(false);
  }, [openCheckExecution, isDisruptionDialogOpen]);

  const confirmCheck = () => {
    if (hasAllRequiredTemperatures && hasValidTemperatures) {
      const temperatures = [...Array(sampleSize).keys()].map((sampleIndex) => {
        return Number(currentTemperatures[sampleIndex]!);
      });

      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          CheckAttributeType.Temperature,
          {
            temperatures,
          }
        )
      );
    }
  };

  const temperatureValueInputChanged = (sampleIndex: number, value: string) => {
    if (currentTemperatures[sampleIndex] !== value) {
      let temps = currentTemperatures;
      temps[sampleIndex] = value;
      setCurrentTemperatures(temps);

      validateTemperatureCount();
      validateTemperatureValues();
    }
  };

  const validateTemperatureCount = () => {
    for (let i = 0; i < sampleSize; i++) {
      const val = currentTemperatures[i];
      if (val === undefined || val === '') {
        setHasAllRequiredTemperatures(false);
        return;
      }
    }
    setHasAllRequiredTemperatures(true);
  };

  const validateTemperatureValues = () => {
    for (let i = 0; i < sampleSize; i++) {
      const val = currentTemperatures[i];
      if (!(val === '' || (val !== '' && Number(val) >= -500.0 && Number(val) <= 1000.0))) {
        setHasValidTemperatures(false);
        return;
      }
    }
    setHasValidTemperatures(true);
  };

  const confirmationHandler = () => {
    setSubmitted(true);
    confirmCheck();
  };

  const getCheckDescription = () => {
    return (
      <InfoHeaderContainer data-testid="checkInfoHeader">
        <CheckDescriptionComponent text={checkAttribute.temperatureDescription} />
      </InfoHeaderContainer>
    );
  };

  const getInputField = (sampleIndex: number) => {
    return (
      <div key={sampleIndex}>
        <CheckInputContainer>
          {sampleSize > 1 && (
            <Typography fontWeight={'bold'}>
              {`${t('data:check.sampleNumber', { sampleNumber: sampleIndex + 1 })}`}
            </Typography>
          )}
          <InputItem>
            <FormattedSampleInputComponent
              name={`temperatureValue_${openCheckExecution.productionRunCheckExecutionId}_${sampleIndex}_${isDisruptionDialogOpen}`}
              onInputChange={temperatureValueInputChanged}
              sampleIndex={sampleIndex}
              values={currentTemperatures}
              label={t('data:check.temperature')}
              ariaLabel={t('data:check.temperature')}
              suffixUnit={TEMPERATURE_ENTITY}
            />
          </InputItem>
        </CheckInputContainer>
      </div>
    );
  };

  const getInfoBox = () => {
    return (
      <InfoboxComponent
        headline={
          sampleSize > 1
            ? t('data:check.temperatureLimitsError')
            : t('data:check.temperatureLimitError')
        }
        type="error"
      />
    );
  };

  const getConfirmationButton = () => {
    return (
      <CheckConfirmationButtonComponent
        handleClick={confirmationHandler}
        disabled={!hasAllRequiredTemperatures}
      />
    );
  };

  return (
    <Paper>
      <CheckAccordion
        checkAttribute={checkAttribute}
        detailsContent={getCheckDescription()}
        productionRun={productionRun}
      />
      <ContainerInside>
        <GridContainer level={GridLevel.CheckPaper}>
          <GridItem>
            <SamplesContainer>
              {[...Array(sampleSize || 1).keys()].map((sampleIndex) => {
                return getInputField(sampleIndex);
              })}
            </SamplesContainer>
          </GridItem>

          {submitted && !hasValidTemperatures && <GridItem>{getInfoBox()}</GridItem>}
          <GridItem>{getConfirmationButton()}</GridItem>
        </GridContainer>
      </ContainerInside>
    </Paper>
  );
};
