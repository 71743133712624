import { Button, Typography, styled, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ProductionRun } from '../../../model';
import { takeOverScale } from '../../../store';
import { BackToOverviewLink } from '../../back-link/back-link.component';

const PREFIX = 'competingOrdersDialog';

const classes = {
  header: `${PREFIX}-header`,
  stepSpacing: `${PREFIX}-stepSpacing`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  blockingOrderNumber: string;
}

export const CompetingOrdersInfoComponent = (props: OwnProps) => {
  const { productionRun, blockingOrderNumber } = props;
  const checkExecutionId = productionRun.openCheckExecutions[0].productionRunCheckExecutionId;
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();

  const handleTakeOverButtonClick = () => {
    dispatch(takeOverScale(productionRun.id, checkExecutionId));
  };

  return (
    <Root data-testid="competingOrdersDialog">
      <Box mb={3}>
        <BackToOverviewLink />
      </Box>
      <Box className={classes.header}>
        <Typography variant="h4">
          <b>{t('data:check.competingOrdersPopover.competingOrders')}</b>
        </Typography>
      </Box>
      <Typography textAlign="justify">
        {t('data:check.competingOrdersPopover.deviceInUseA')}
        <b data-testid="blockingOrderNumber">{blockingOrderNumber}</b>
        {t('data:check.competingOrdersPopover.deviceInUseB')}
      </Typography>

      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleTakeOverButtonClick}
          data-testid="takeoverButton"
        >
          {t('data:check.competingOrdersPopover.takeOverDeviceCaps')}
        </Button>
      </Box>
    </Root>
  );
};
