import { Typography, styled, Box, Button, useMediaQuery, Theme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CheckAttributeType, ProductionRun } from '../../model';
import { appendProductionRunChecks, closeAppendProductionRunChecks } from '../../store';
import { irisSpacing, maxScreenBreakpoint } from '../../theme';

export interface OwnProps {
  productionRun: ProductionRun;
}

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(irisSpacing.h2.mb),
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  gap: theme.spacing(irisSpacing.button.space),
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  marginTop: theme.spacing(3),
  border: 0,
  maxHeight: 520,
  '& .MuiDataGrid-columnHeaderTitle': {
    lineBreak: 'auto',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5',
  },
  '& .MuiDataGrid-cellContent': {
    lineBreak: 'auto',
    whiteSpace: 'break-spaces',
  },
  '& .MuiDataGrid-cell': {
    padding: '10px',
  },
}));

const SelectedCountText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  alignSelf: 'center',
  marginRight: 'auto',
}));

export const AppendInfoComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation(['data', 'form']);
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const isAppendButtonDisabled = selectedIds.length < 1;
  const productionRunChecks = productionRun.checks;
  const appendableProductionRunChecks = productionRunChecks.filter(
    (item) =>
      item.checkPeriodicity && item.checkAttribute.checkAttributeType !== CheckAttributeType.Tare
  );
  const appendableChecks: any[] = [];

  const handleCancel = () => {
    dispatch(closeAppendProductionRunChecks());
  };

  const handleAppendChecks = () => {
    dispatch(appendProductionRunChecks(productionRun.id, selectedIds));
  };

  const bigScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(maxScreenBreakpoint));

  const columns: GridColDef[] = [
    {
      field: 'checkName',
      headerName: t('data:check.nameOfCheck'),
      minWidth: bigScreen ? 550 : 350,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'checkAttributeType',
      headerName: t('data:check.checkAttributeType'),
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'sampleSize',
      headerName: t('data:check.sampleSize'),
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  for (var i = 0; i < appendableProductionRunChecks.length; i++) {
    appendableChecks.push({
      id: appendableProductionRunChecks[i].id,
      checkName: appendableProductionRunChecks[i].checkAttribute.name,
      checkAttributeType: t([
        `data:check.checkAttributeTypeCodes.${appendableProductionRunChecks[i].checkAttribute.checkAttributeType}`,
        `data:checkAttribute.checkAttributeTypeCodes.notFound`,
      ]),
      sampleSize: appendableProductionRunChecks[i].checkAttribute.sampleSize,
    });
  }

  return (
    <>
      <Header variant="h4">{t('data:productionRun.appendChecks')}</Header>
      <Typography>{t('data:productionRun.appendChecksSelection')}</Typography>

      <StyledDataGrid
        rows={appendableChecks}
        columns={columns}
        getRowHeight={() => 'auto'}
        checkboxSelection
        hideFooter
        onRowSelectionModelChange={(ids) => {
          setSelectedIds(ids);
        }}
        data-testid="checksDataGrid"
      />

      <ButtonBox>
        <SelectedCountText>
          {t('data:productionRun.selectedChecksCount', {
            selectedChecksCount: selectedIds.length,
            maximumSelectedChecksCount: appendableProductionRunChecks.length,
          })}
        </SelectedCountText>
        <Button color="secondary" variant="contained" onClick={handleCancel}>
          {t('form:cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isAppendButtonDisabled}
          onClick={handleAppendChecks}
          data-testid="appendChecksButton"
        >
          {t('data:productionRun.appendChecks')}
        </Button>
      </ButtonBox>
    </>
  );
};
