import { createSelector } from 'reselect';

import { AppState } from '..';

export const notificationsStateSelector = (state: AppState) => state.notifications;
export const automaticWeighingErrorsSelector = createSelector(
  notificationsStateSelector,
  (state) => state.automaticWeighingErrors
);
export const currentNotificationSelector = createSelector(
  notificationsStateSelector,
  (state) => state.currentNotification
);
