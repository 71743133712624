import { useDispatch, useSelector } from 'react-redux';

import { CheckAttributeType } from '../../../model';
import { confirmCheckForProductionRun, hasAllRequiredCheckWeightsSelector } from '../../../store';
import { ContainerOutside } from '../../structure';
import { CheckProps } from '../check.component';

import { AverageWeightComponent, AverageWeightMode } from './average-weight.component';
import { FertigPackVCategoriesComponent } from './fertigpackv-categories.component';
import { FertigPackVCheckMeasurementComponent } from './fertigpackv-check-measurement.component';

export interface OwnProps {
  checkProps: CheckProps;
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void;
}

export const FertigPackVCheckComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { checkProps, executeMeasurement } = props;
  const { productionRun, openCheckExecution, checkAttribute } = checkProps;
  const hasAllRequiredSamples = useSelector(hasAllRequiredCheckWeightsSelector);
  const testRunActive = checkAttribute.checkAttributeType === CheckAttributeType.TestRun;
  const tareDeterminationActive = checkAttribute.checkAttributeType === CheckAttributeType.Tare;

  const confirmCheck = () => {
    if (testRunActive || hasAllRequiredSamples) {
      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          checkAttribute.checkAttributeType
        )
      );
    }
  };

  return (
    <div data-testid="FertigPackVCheckComponent">
      <FertigPackVCheckMeasurementComponent
        checkProps={checkProps}
        confirmCheck={confirmCheck}
        executeMeasurement={executeMeasurement}
        testRunActive={testRunActive}
        tareDeterminationActive={tareDeterminationActive}
      />
      {tareDeterminationActive || (
        <>
          <ContainerOutside>
            <AverageWeightComponent
              productionRun={productionRun}
              defaultAverageWeightMode={AverageWeightMode.Execution}
            />
          </ContainerOutside>
          <ContainerOutside>
            <FertigPackVCategoriesComponent productionRun={productionRun} />
          </ContainerOutside>
        </>
      )}
    </div>
  );
};
