import { DeviceStatus } from '../../model';

export enum DevicesActionType {
  scaleGetHeartbeat = 'scale/getHeartbeat',
  scaleGetHeartbeatSuccess = 'scale/getHeartbeat/success',
  scaleGetHeartbeatFailure = 'scale/getHeartbeat/failure',

  scaleSetIdentificator = 'scale/setIdentificator',
  scaleSetIdentificatorSuccess = 'scale/setIdentificator/success',
  scaleSetIdentificatorFailure = 'scale/setIdentificator/failure',

  takeOverScale = 'scale/takeOver',
  takeOverScaleSuccess = 'scale/takeOver/success',
  takeOverScaleFailure = 'scale/takeOver/failure',

  unsetStaticScaleDevice = 'scale/unset/request',
  unsetStaticScaleDeviceSuccess = 'scale/unset/success',
  unsetStaticScaleDeviceFailure = 'scale/unset/failure',
}

export const getHeartbeat = (scaleId: string) => ({
  type: DevicesActionType.scaleGetHeartbeat,
  payload: { scaleId },
});

export const getHeartbeatSuccess = (deviceStatus: DeviceStatus) => ({
  type: DevicesActionType.scaleGetHeartbeatSuccess,
  payload: { deviceStatus },
});

export const getHeartbeatFailure = () => ({
  type: DevicesActionType.scaleGetHeartbeatFailure,
});

export const setIdentificator = (productionRunId: string, checkExecutionId: string) => ({
  type: DevicesActionType.scaleSetIdentificator,
  payload: { productionRunId, checkExecutionId },
});

export const setIdentificatorSuccess = (deviceStatus: DeviceStatus) => ({
  type: DevicesActionType.scaleSetIdentificatorSuccess,
  payload: { deviceStatus },
});

export const setIdentificatorFailure = (e: any) => ({
  type: DevicesActionType.scaleSetIdentificatorFailure,
  payload: { e },
});

export const takeOverScale = (productionRunId: string, checkExecutionId: string) => ({
  type: DevicesActionType.takeOverScale,
  payload: { productionRunId, checkExecutionId },
});

export const takeOverScaleSuccess = (deviceStatus: DeviceStatus) => ({
  type: DevicesActionType.takeOverScaleSuccess,
  payload: { deviceStatus },
});

export const takeOverScaleFailure = (e: any) => ({
  type: DevicesActionType.takeOverScaleFailure,
  payload: { e },
});

export const unsetStaticScaleDevice = (productionRunId: string) => ({
  type: DevicesActionType.unsetStaticScaleDevice,
  payload: { productionRunId },
});

export const unsetStaticScaleDeviceSuccess = () => ({
  type: DevicesActionType.unsetStaticScaleDeviceSuccess,
});

export const unsetStaticScaleDeviceFailure = () => ({
  type: DevicesActionType.unsetStaticScaleDeviceFailure,
});
