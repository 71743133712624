import { Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CheckAttribute, CheckAttributeType } from '../../../model';
import { confirmCheckForProductionRun } from '../../../store';
import { irisCheckResultColors } from '../../../theme';
import { CheckAccordion } from '../../accordion/check-accordion.component';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { GridContainer, GridLevel } from '../../grid/grid.container';
import { GridItem } from '../../grid/grid.item';
import { ResponsiveImageComponent } from '../../image/responsive-image/responsive-image.component';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { SampleSizeSelectComponent } from '../../sample-size-select/sample-size-select.component';
import { ContainerInside, InfoHeaderContainer } from '../../structure';
import { CheckProps } from '../check.component';
import {
  CheckConfirmationButtonComponent,
  CheckDescriptionComponent,
} from '../common/check.components';

import {
  TrafficLightCenterContainer,
  TrafficLightAvatar,
  TrafficLightBorder,
  TrafficLightDescription,
  TrafficLightIconBackground,
  TrafficLightCheckIcon,
  TrafficLightCloseIcon,
} from './traffic-light-check.styles';

enum Answer {
  Green,
  Yellow,
  Red,
}

const hasGreenCheckItem = (checkAttribute: CheckAttribute) => {
  return checkAttribute.trafficLightGreenDescription || checkAttribute.trafficLightGreenImageUrl;
};

const hasYellowCheckItem = (checkAttribute: CheckAttribute) => {
  return checkAttribute.trafficLightYellowDescription || checkAttribute.trafficLightYellowImageUrl;
};

const hasRedCheckItem = (checkAttribute: CheckAttribute) => {
  return checkAttribute.trafficLightRedDescription || checkAttribute.trafficLightRedImageUrl;
};

const getGridColumnCount = (checkAttribute: CheckAttribute) => {
  let columnCount = 0;
  columnCount += hasGreenCheckItem(checkAttribute) ? 1 : 0;
  columnCount += hasYellowCheckItem(checkAttribute) ? 1 : 0;
  columnCount += hasRedCheckItem(checkAttribute) ? 1 : 0;
  return columnCount as 1 | 2 | 3;
};

export const TrafficLightCheckComponent = (props: CheckProps) => {
  const { productionRun, openCheckExecution, isDisruptionDialogOpen, checkAttribute } = props;

  const columnCount = getGridColumnCount(checkAttribute);
  const gridCols = (12 / columnCount) as 12 | 6 | 4;
  const hasGreen = hasGreenCheckItem(checkAttribute);
  const hasYellow = hasYellowCheckItem(checkAttribute);
  const hasRed = hasRedCheckItem(checkAttribute);

  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const { sampleSize } = checkAttribute;

  const [answeredGreen, setAnsweredGreen] = useState<number | string>('');
  const [answeredYellow, setAnsweredYellow] = useState<number | string>('');
  const [answeredRed, setAnsweredRed] = useState<number | string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hasAllRequiredSamples, setHasAllRequiredSamples] = useState<boolean>(true);

  useEffect(() => {
    let sum = answeredGreen ? (answeredGreen as number) : 0;
    sum += answeredYellow ? (answeredYellow as number) : 0;
    sum += answeredRed ? (answeredRed as number) : 0;
    setHasAllRequiredSamples(sum === sampleSize);
  }, [answeredGreen, answeredYellow, answeredRed, sampleSize]);

  useEffect(() => {
    setAnsweredGreen('');
    setAnsweredYellow('');
    setAnsweredRed('');
    setSubmitted(false);
  }, [openCheckExecution, isDisruptionDialogOpen]);

  const confirmCheck = (answeredGreen: number, answeredYellow: number, answeredRed: number) => {
    dispatch(
      confirmCheckForProductionRun(
        productionRun.id,
        openCheckExecution,
        CheckAttributeType.TrafficLight,
        {
          answeredGreen,
          answeredYellow,
          answeredRed,
        }
      )
    );
  };

  const confirmationHandler = () => {
    setSubmitted(true);
    hasAllRequiredSamples &&
      confirmCheck(
        answeredGreen ? (answeredGreen as number) : 0,
        answeredYellow ? (answeredYellow as number) : 0,
        answeredRed ? (answeredRed as number) : 0
      );
  };

  //#region Top
  const getCheckDetails = () => {
    return (
      <InfoHeaderContainer data-testid="checkInfoHeader">
        <CheckDescriptionComponent text={checkAttribute.trafficLightDescription} />
      </InfoHeaderContainer>
    );
  };
  //#endregion

  //#region Headline
  const getHeadlineText = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return t('data:checkAttribute.trafficLightGreenDescription');
      case Answer.Yellow:
        return t('data:checkAttribute.trafficLightYellowDescription');
      case Answer.Red:
        return t('data:checkAttribute.trafficLightRedDescription');
    }
  };

  const getHeadlineColumn = (answer: Answer) => {
    return <Typography fontWeight={'bold'}>{getHeadlineText(answer)}</Typography>;
  };

  const getHeadlineRow = () => {
    return (
      <GridContainer level={GridLevel.CheckPaper}>
        {hasGreen && <GridItem s={gridCols}>{getHeadlineColumn(Answer.Green)}</GridItem>}
        {hasYellow && <GridItem s={gridCols}>{getHeadlineColumn(Answer.Yellow)}</GridItem>}
        {hasRed && <GridItem s={gridCols}>{getHeadlineColumn(Answer.Red)}</GridItem>}
      </GridContainer>
    );
  };
  //#endregion

  //#region Image
  const getImageUrl = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return checkAttribute.trafficLightGreenImageUrl;
      case Answer.Yellow:
        return checkAttribute.trafficLightYellowImageUrl;
      case Answer.Red:
        return checkAttribute.trafficLightRedImageUrl;
    }
  };

  const getBorderColor = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return irisCheckResultColors.passed;
      case Answer.Yellow:
        return irisCheckResultColors.sufficient;
      case Answer.Red:
        return irisCheckResultColors.failed;
    }
  };

  const getImageColumn = (answer: Answer) => {
    const imageUrl = getImageUrl(answer);
    const borderColor = getBorderColor(answer);

    return (
      <>
        {!!imageUrl && (
          <>
            <TrafficLightIconBackground>
              <TrafficLightAvatar>
                {answer === Answer.Red ? (
                  <TrafficLightCloseIcon styleProps={{ color: borderColor }} />
                ) : (
                  <TrafficLightCheckIcon styleProps={{ color: borderColor }} />
                )}
              </TrafficLightAvatar>
            </TrafficLightIconBackground>
            <TrafficLightBorder styleProps={{ color: borderColor }}>
              <ResponsiveImageComponent imageUrl={imageUrl} altText={t('form:image')} />
            </TrafficLightBorder>
          </>
        )}
      </>
    );
  };

  const getImageRow = () => {
    return (
      <GridContainer level={GridLevel.CheckPaper}>
        {hasGreen && <GridItem s={gridCols}>{getImageColumn(Answer.Green)}</GridItem>}
        {hasYellow && <GridItem s={gridCols}>{getImageColumn(Answer.Yellow)}</GridItem>}
        {hasRed && <GridItem s={gridCols}>{getImageColumn(Answer.Red)}</GridItem>}
      </GridContainer>
    );
  };
  //#endregion

  //#region Description
  const getDescriptionText = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return checkAttribute.trafficLightGreenDescription;
      case Answer.Yellow:
        return checkAttribute.trafficLightYellowDescription;
      case Answer.Red:
        return checkAttribute.trafficLightRedDescription;
    }
  };

  const getDescriptionColumn = (answer: Answer) => {
    const borderColor = getBorderColor(answer);
    const description = getDescriptionText(answer);

    return (
      <>
        {description && (
          <>
            <TrafficLightIconBackground>
              <TrafficLightAvatar>
                {answer === Answer.Red ? (
                  <TrafficLightCloseIcon styleProps={{ color: borderColor }} />
                ) : (
                  <TrafficLightCheckIcon styleProps={{ color: borderColor }} />
                )}
              </TrafficLightAvatar>
            </TrafficLightIconBackground>
            <TrafficLightBorder styleProps={{ color: borderColor }}>
              <TrafficLightDescription variant="body1">{description}</TrafficLightDescription>
            </TrafficLightBorder>
          </>
        )}
      </>
    );
  };

  const getDescriptionRow = () => {
    return (
      <GridContainer level={GridLevel.CheckPaper}>
        {hasGreen && <GridItem s={gridCols}>{getDescriptionColumn(Answer.Green)}</GridItem>}
        {hasYellow && <GridItem s={gridCols}>{getDescriptionColumn(Answer.Yellow)}</GridItem>}
        {hasRed && <GridItem s={gridCols}>{getDescriptionColumn(Answer.Red)}</GridItem>}
      </GridContainer>
    );
  };
  //#endregion

  //#region Selection
  const confirmSingleSample = (answer: Answer) => {
    const sampleGreen = answer === Answer.Green ? 1 : 0;
    const sampleYellow = answer === Answer.Yellow ? 1 : 0;
    const sampleRed = answer === Answer.Red ? 1 : 0;

    confirmCheck(sampleGreen, sampleYellow, sampleRed);
  };

  const getSelectionColumn = (
    answer: Answer,
    sampleValue: string | number,
    setSampleValue: (value: string | number) => void
  ) => {
    return (
      <>
        {sampleSize > 1 ? (
          <SampleSizeSelectComponent
            sampleSize={sampleSize}
            sampleValue={sampleValue}
            setSampleValue={setSampleValue}
          />
        ) : (
          <TrafficLightCenterContainer>
            <ConfirmButtonComponent
              handleClick={() => {
                confirmSingleSample(answer);
              }}
            />
          </TrafficLightCenterContainer>
        )}
      </>
    );
  };

  const getSelectionRow = () => {
    return (
      <GridContainer level={GridLevel.CheckPaper}>
        {hasGreen && (
          <GridItem s={gridCols}>
            {getSelectionColumn(Answer.Green, answeredGreen, setAnsweredGreen)}
          </GridItem>
        )}
        {hasYellow && (
          <GridItem s={gridCols}>
            {getSelectionColumn(Answer.Yellow, answeredYellow, setAnsweredYellow)}
          </GridItem>
        )}
        {hasRed && (
          <GridItem s={gridCols}>
            {getSelectionColumn(Answer.Red, answeredRed, setAnsweredRed)}
          </GridItem>
        )}
      </GridContainer>
    );
  };
  //#endregion

  //#region Bottom
  const getInfoBox = () => {
    return <InfoboxComponent headline={t('data:check.sampleSizeDoesNotMatchError')} type="error" />;
  };

  const getConfirmationButton = () => {
    return <CheckConfirmationButtonComponent handleClick={confirmationHandler} />;
  };
  //#endregion

  return (
    <Paper>
      <CheckAccordion
        checkAttribute={checkAttribute}
        detailsContent={getCheckDetails()}
        productionRun={productionRun}
      />
      <ContainerInside>
        <GridContainer level={GridLevel.CheckPaper}>
          <GridItem>
            <Grid container direction={'column'} gap={3}>
              {getHeadlineRow()}
              {getImageRow()}
              {getDescriptionRow()}
              {getSelectionRow()}
            </Grid>
          </GridItem>

          {sampleSize > 1 && (
            <>
              {submitted && !hasAllRequiredSamples && <GridItem>{getInfoBox()}</GridItem>}
              <GridItem>{getConfirmationButton()}</GridItem>
            </>
          )}
        </GridContainer>
      </ContainerInside>
    </Paper>
  );
};
