import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filterIssues, getFertigPackVStatistics, getSuffix } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';
import {
  calcFertigPackTolerances,
  clearFertigPackTolerances,
  fertigPackTolerancesSelector,
} from '../../../store';
import { FormattedNumberOutputComponent } from '../../formatted-number-output/formatted-number-output.component';
import { IssuesComponent } from '../../issue/issues.component';
import { ContainerInside } from '../../structure';
import { ToleranceBarComponent } from '../../tolerance-bar/tolerance-bar.component';

const StyledOuterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
  flexDirection: 'column',
}));

const StyledInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(2),
}));

const StyledHeadlineBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: 'auto',
}));

export interface OwnProps {
  productionRun: ProductionRun;
  defaultAverageWeightMode: AverageWeightMode;
}

export enum AverageWeightMode {
  Charge = 1,
  Execution = 2,
}

export const AverageWeightComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();
  const { productionRun, defaultAverageWeightMode } = props;
  const nominalValue = productionRun.article.nominalValue;
  const densityValue = productionRun.article.densityValue;
  const measurementUnit = productionRun.article.measurementUnit;
  const currentTolerances = useSelector(fertigPackTolerancesSelector);
  const statistics = getFertigPackVStatistics(productionRun);
  const filteredIssues = filterIssues(productionRun.issues, [IssueCode.AverageWeightBelowNominal]);
  const [averageWeightMode, setAverageWeightMode] =
    useState<AverageWeightMode>(defaultAverageWeightMode);

  const hasChecksDone =
    (averageWeightMode === AverageWeightMode.Charge &&
      statistics?.checkSamplesDone &&
      statistics.checkSamplesDone > 0) ||
    (averageWeightMode === AverageWeightMode.Execution &&
      statistics?.checkSamplesDoneLastExecution &&
      statistics.checkSamplesDoneLastExecution > 0);
  const hasAverage =
    ((!!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume) &&
      averageWeightMode === AverageWeightMode.Charge) ||
    ((!!statistics?.checkSamplesAverageWeightLastExecution ||
      !!statistics?.checkSamplesAverageVolumeLastExecution) &&
      averageWeightMode === AverageWeightMode.Execution);
  const hasStandardDeviation =
    ((!!statistics?.checkSamplesStandardDeviation ||
      !!statistics?.checkSamplesStandardVolumeDeviation) &&
      averageWeightMode === AverageWeightMode.Charge) ||
    ((!!statistics?.checkSamplesStandardDeviationLastExecution ||
      !!statistics?.checkSamplesStandardVolumeDeviationLastExecution) &&
      averageWeightMode === AverageWeightMode.Execution);
  const standardDeviation =
    statistics?.checkSamplesStandardVolumeDeviation ?? statistics?.checkSamplesStandardDeviation;
  const standardDeviationLastExecution =
    statistics?.checkSamplesStandardVolumeDeviationLastExecution ??
    statistics?.checkSamplesStandardDeviationLastExecution;
  const averageValue =
    statistics?.checkSamplesAverageVolume ?? statistics?.checkSamplesAverageWeight;
  const averageValueLastExecution =
    statistics?.checkSamplesAverageVolumeLastExecution ??
    statistics?.checkSamplesAverageWeightLastExecution;
  const suffix = getSuffix(!!productionRun.article.volumeValue, measurementUnit);

  useEffect(() => {
    if (densityValue && nominalValue && measurementUnit) {
      dispatch(calcFertigPackTolerances(measurementUnit, nominalValue, densityValue));
    } else if (nominalValue && measurementUnit) {
      dispatch(calcFertigPackTolerances(measurementUnit, nominalValue));
    }
    return () => {
      dispatch(clearFertigPackTolerances());
    };
  }, [dispatch, nominalValue, densityValue, measurementUnit]);

  const handleAverageWeightModeChange = (
    event: React.MouseEvent<HTMLElement>,
    mode: AverageWeightMode
  ) => {
    if (mode !== null) {
      setAverageWeightMode(mode);
    }
  };

  const getValueForAverageWeightMode = <T extends boolean | number>(
    valueForCharge?: T,
    valueForExecution?: T
  ) => {
    switch (averageWeightMode) {
      case AverageWeightMode.Charge:
        return valueForCharge;
      case AverageWeightMode.Execution:
        return valueForExecution;
    }
  };

  return (
    <Paper data-testid={'averageWeightComponent'}>
      <ContainerInside>
        <StyledOuterBox>
          <Box mb={2}>
            <StyledHeadlineBox>
              <Typography align="left" variant="h3">
                {hasAverage
                  ? t('data:check.fertigPackVCurrentAverage')
                  : t('data:check.fertigPackV')}
              </Typography>
              <StyledToggleButtonGroup
                value={averageWeightMode}
                exclusive
                onChange={handleAverageWeightModeChange}
                data-testid="averageWeightModeToggleButtonGroup"
              >
                <ToggleButton value={AverageWeightMode.Charge}>
                  {t('data:check.charge')}
                </ToggleButton>
                <ToggleButton value={AverageWeightMode.Execution}>
                  {t('data:check.check')}
                </ToggleButton>
              </StyledToggleButtonGroup>
            </StyledHeadlineBox>
            {hasChecksDone && (
              <StyledInfoBox data-testid="averageWeightSamplesAmountInfo">
                <Typography data-testid="amountSamplesDisplay">
                  {`${t('data:check.samplesAmount')} `}
                  {getValueForAverageWeightMode(
                    statistics.checkSamplesDone,
                    statistics.checkSamplesDoneLastExecution
                  )}
                </Typography>

                {hasAverage && (
                  <>
                    <Typography marginX={1}>{'|'}</Typography>
                    <Typography data-testid="averageWeightDisplay">
                      {`${t('data:check.averageWeight')} `}
                      <FormattedNumberOutputComponent
                        value={getValueForAverageWeightMode(
                          averageValue,
                          averageValueLastExecution
                        )}
                        suffix={suffix}
                      />
                    </Typography>
                  </>
                )}

                {hasStandardDeviation && (
                  <>
                    <Typography marginX={1}>{'|'}</Typography>
                    <Typography data-testid="standardDeviationDisplay">
                      {`${t('data:check.standardDeviation')} `}
                      <FormattedNumberOutputComponent
                        value={getValueForAverageWeightMode(
                          standardDeviation,
                          standardDeviationLastExecution
                        )}
                        suffix={suffix}
                        decimalScale={2}
                      />
                    </Typography>
                  </>
                )}
              </StyledInfoBox>
            )}
          </Box>
        </StyledOuterBox>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ToleranceBarComponent
            nominalValue={
              productionRun.article.volumeValue
                ? productionRun.article.volumeValue
                : productionRun.article.nominalValue
            }
            tolerances={currentTolerances}
            lowerTolerance={productionRun.article.lowerTolerance}
            upperTolerance={productionRun.article.upperTolerance}
            averageValue={getValueForAverageWeightMode(averageValue, averageValueLastExecution)}
            averagePassed={getValueForAverageWeightMode(
              statistics?.checkSamplesAverageWeightPassed,
              statistics?.checkSamplesAverageWeightPassedLastExecution
            )}
            averageSufficient={getValueForAverageWeightMode(
              statistics?.checkSamplesAverageWeightSufficient,
              statistics?.checkSamplesAverageWeightSufficientLastExecution
            )}
            averageFailed={getValueForAverageWeightMode(
              statistics?.checkSamplesAverageWeightFailed,
              statistics?.checkSamplesAverageWeightFailedLastExecution
            )}
            unit={measurementUnit}
          />
        </Box>
        {filteredIssues.length > 0 && averageWeightMode === AverageWeightMode.Charge && (
          <Box sx={{ marginTop: 3 }}>
            <IssuesComponent issues={filteredIssues} />
          </Box>
        )}
      </ContainerInside>
    </Paper>
  );
};
