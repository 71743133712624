import { Box, styled } from '@mui/material';

import { irisCustomColors } from '../../theme';

export interface StylePropsWrapper {
  styleProps: {
    percentage: number;
  };
}

export const FlexEndContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const GiveAwayContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const GiveAwayGoldBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  marginTop: theme.spacing(1.5),
  height: theme.spacing(3),
  width: `${styleProps.percentage}%`,
  backgroundColor: irisCustomColors.irisGold,
}));

export const GiveAwayWhiteBox = styled(GiveAwayGoldBox)(({ theme }) => ({
  opacity: 0.2,
}));

export const ZeroPoint = styled('div')(({ theme }) => ({
  width: '1px',
  height: theme.spacing(6),
  borderRight: '2px',
  borderRightStyle: 'solid',
  borderRightColor: irisCustomColors.irisGrayDimmed,
}));

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
}));
