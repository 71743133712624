import {
  CheckAttribute,
  CheckAttributeType,
  ProductionRunArticle,
  TareMode,
  ProductionRunCheckExecutionWeighingSampleResult,
} from '../../model';

export enum WeighingCategory {
  Unknown = 0,
  FertigPackV = 1,
  Weight = 2,
  Volume = 3,
}

export const getWeighingCategory = (checkAttributeType: CheckAttributeType) => {
  switch (checkAttributeType) {
    case CheckAttributeType.FertigPackV:
    case CheckAttributeType.TestRun:
    case CheckAttributeType.Tare:
      return WeighingCategory.FertigPackV;
    case CheckAttributeType.Weight:
      return WeighingCategory.Weight;
    case CheckAttributeType.Volume:
      return WeighingCategory.Volume;
    default:
      return WeighingCategory.Unknown;
  }
};

export const getTare = (
  checkAttribute: CheckAttribute,
  article: ProductionRunArticle,
  hasAllRequiredTareWeights: boolean,
  individualTare?: number
) => {
  switch (getWeighingCategory(checkAttribute.checkAttributeType)) {
    case WeighingCategory.FertigPackV:
      return article.tareMode !== TareMode.IndividualTare && article.tareValue
        ? article.tareValue
        : hasAllRequiredTareWeights
        ? individualTare ?? 0
        : 0;
    case WeighingCategory.Weight:
      return checkAttribute.weightTareValue;
    case WeighingCategory.Volume:
      return checkAttribute.volumeTareValue;
    default:
      return undefined;
  }
};

export const getWeighingSample = (
  currentWeights: { [key: string]: ProductionRunCheckExecutionWeighingSampleResult },
  sampleIndex: number
): ProductionRunCheckExecutionWeighingSampleResult | undefined =>
  currentWeights && currentWeights[`weight${sampleIndex}`];
