import { WebsocketConfig } from '../websockets';

import {
  changedStaticScaleDeviceStatus,
  occuredStaticScaleDeviceIssue,
} from './devices.websocket-actions';

export const devicesWebsocketConfig: WebsocketConfig = {
  url: 'devices',
  actions: [
    {
      methodName: 'StaticScaleDeviceStatusChanged',
      actionCreator: changedStaticScaleDeviceStatus,
    },
    {
      methodName: 'StaticScaleDeviceIssueOccurred',
      actionCreator: occuredStaticScaleDeviceIssue,
    },
  ],
};
