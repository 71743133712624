import { produce } from 'immer';
import { Action } from 'redux';

import { DEFAULT_LINE_ID } from '../../constants';
import { Line } from '../../model';

import { LinesActionType, fetchLinesSuccess, changeLine, initLine } from './lines.actions';

export interface LinesState {
  lines: Line[];
  currentLineId: string;
  linesFetched?: boolean;
}

export const defaultLine: Line = {
  id: DEFAULT_LINE_ID,
  name: '',
  lastModified: '',
};

export const getInitialState = (): LinesState => {
  return {
    lines: [defaultLine],
    currentLineId: DEFAULT_LINE_ID,
  };
};

export const linesReducer = (
  previousState: LinesState = getInitialState(),
  action: Action<LinesActionType>
) => {
  let nextState;

  switch (action.type) {
    case LinesActionType.linesFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.linesFetched = false;
      });
      break;
    case LinesActionType.linesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchLinesSuccess>;
        draftState.lines = [defaultLine].concat(fetchSuccessAction.payload.lines);
        if (!draftState.currentLineId) draftState.currentLineId = DEFAULT_LINE_ID;
        draftState.linesFetched = true;
      });
      break;
    case LinesActionType.linesFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.linesFetched = true;
      });
      break;
    case LinesActionType.lineInit:
    case LinesActionType.lineChange:
      nextState = produce(previousState, (draftState) => {
        const changeAction = action as ReturnType<typeof changeLine | typeof initLine>;
        draftState.currentLineId = changeAction.payload.lineId;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
