import { produce } from 'immer';
import { Action } from 'redux';

import { InternalErrorCode, ScaleErrorCode } from '../../http/http-error-codes';
import { AuthenticationActionType } from '../authentication';
import {
  CheckExecutionsActionType,
  CheckExecutionsWebsocketActionType,
  issueOccurredCheckExecution,
} from '../check-executions';
import { DevicesActionType, setIdentificatorFailure, takeOverScaleFailure } from '../devices';
import {
  DevicesWebsocketActionType,
  occuredStaticScaleDeviceIssue,
} from '../devices/devices.websocket-actions';
import { ProductionOrdersActionType } from '../production-orders';
import {
  confirmCheckForProductionRunSuccess,
  ProductionRunsActionType,
  stopProductionRunSuccess,
} from '../production-runs';
import { executeCheckWeightFailure, WeightsActionType } from '../weights';

import { Notification, NotificationType, ProductionRunStatus } from './../../model';
import { LinesActionType } from './../lines';
import { NotificationsActionType, setNotification } from './notifications.actions';

export interface NotificationsState {
  automaticWeighingErrors: { [id: string]: number };
  currentNotification?: Notification;
}

export const getInitialState = (): NotificationsState => ({
  automaticWeighingErrors: {},
});

const addErrorNotification = (
  draftState: NotificationsState,
  type: any,
  errorCode?: InternalErrorCode
) => {
  draftState.currentNotification = errorCode
    ? {
        messageKey: type,
        type: NotificationType.error,
        errorCode: errorCode,
      }
    : {
        messageKey: type,
        type: NotificationType.error,
      };
};

export const notificationsReducer = (
  previousState: NotificationsState = getInitialState(),
  action: Action
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ProductionOrdersActionType.productionOrderStartFailure:
    case ProductionOrdersActionType.productionOrdersFetchAllFailure:
    case ProductionRunsActionType.productionRunsFetchAllFailure:
    case ProductionRunsActionType.productionRunFetchFailure:
    case ProductionRunsActionType.productionRunStopFailure:
    case ProductionRunsActionType.productionRunCheckConfirmFailure:
    case ProductionRunsActionType.productionRunCheckSkipFailure:
    case ProductionRunsActionType.productionRunAppendChecksFailure:
    case ProductionRunsActionType.productionRunDisruptionStartFailure:
    case ProductionRunsActionType.productionRunDisruptionResolveFailure:
    case ProductionRunsActionType.productionRunCalcFertigPackTolerancesFailure:
    case LinesActionType.linesFetchAllFailure:
    case WeightsActionType.checkWeightSamplesFetchFailure:
    case AuthenticationActionType.profileInfoFetchFailure:
    case ProductionRunsActionType.productionRunPauseFailure:
    case ProductionRunsActionType.productionRunContinueFailure:
    case CheckExecutionsActionType.barcodeSampleSendFailure:
    case DevicesActionType.scaleGetHeartbeatFailure:
      nextState = produce(previousState, (draftState) => {
        addErrorNotification(draftState, type);
      });
      break;
    case ProductionRunsActionType.productionRunCheckConfirmSuccess:
    case ProductionRunsActionType.productionRunStopSuccess:
    case ProductionRunsActionType.productionRunCheckSkipSuccess:
    case ProductionRunsActionType.productionRunDisruptionResolveSuccess:
      nextState = produce(previousState, (draftState) => {
        const productionRunAction = action as ReturnType<
          typeof stopProductionRunSuccess | typeof confirmCheckForProductionRunSuccess
        >;
        const { productionRun } = productionRunAction.payload;
        if (productionRun.status === ProductionRunStatus.Done) {
          draftState.currentNotification = {
            messageKey: ProductionRunsActionType.productionRunStopSuccess,
            type: NotificationType.success,
          };
        }
      });
      break;

    case DevicesActionType.takeOverScaleFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof takeOverScaleFailure>;
        const { e } = failureAction.payload;

        if (e.internalErrorCode) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;

    case NotificationsActionType.notificationClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentNotification;
      });
      break;

    case WeightsActionType.checkWeightExecuteFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof executeCheckWeightFailure>;
        const { e } = failureAction.payload;
        if (
          e.internalErrorCode &&
          e.internalErrorCode === InternalErrorCode.StaticScaleCommunicationFailed &&
          e.data?.data?.scaleErrorCode &&
          e.data.data.scaleErrorCode === ScaleErrorCode.TareOrSetNullError
        ) {
          addErrorNotification(draftState, type, e.data.data.scaleErrorCode);
        } else if (
          e.internalErrorCode ===
            InternalErrorCode.ProductionRunCheckExecutionTareValueNotInRange ||
          e.internalErrorCode ===
            InternalErrorCode.ProductionRunCheckExecutionSampleAlreadyDoneException ||
          e.internalErrorCode === InternalErrorCode.ProductionRunCheckExecutionInvalidWeighingbridge
        ) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;

    case CheckExecutionsWebsocketActionType.checkExecutionIssueOccurred:
    case DevicesWebsocketActionType.deviceIssueOccurred:
      nextState = produce(previousState, (draftState) => {
        const issueOccurredAction = action as ReturnType<
          typeof issueOccurredCheckExecution | typeof occuredStaticScaleDeviceIssue
        >;
        const {
          e: { productionRunId, issueCode },
        } = issueOccurredAction.payload;
        draftState.automaticWeighingErrors[productionRunId] = issueCode;
      });
      break;

    case DevicesActionType.scaleSetIdentificatorFailure:
      nextState = produce(previousState, (draftState) => {
        const failedAction = action as ReturnType<typeof setIdentificatorFailure>;
        const { e: error } = failedAction.payload;
        if (error?.data?.data?.scaleErrorCode === 15) {
          addErrorNotification(draftState, type, error?.data?.data?.scaleErrorCode);
        }
      });
      break;

    case NotificationsActionType.notificationSet:
      nextState = produce(previousState, (draftState) => {
        const setNotificationAction = action as ReturnType<typeof setNotification>;
        const { errorCode, productionRunId } = setNotificationAction.payload;
        addErrorNotification(draftState, type, errorCode);
        delete draftState.automaticWeighingErrors[productionRunId];
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
