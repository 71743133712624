import { AppBar, Box, Paper, Toolbar } from '@mui/material';
import { Slide } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import logohfmyer from '../../assets/img/hfmyr-logo.png';
import logo from '../../assets/img/iris-logo.png';
import { AppRoutePath } from '../../routes/routes';
import { irisCustomColors } from '../../theme';
import { irisSpacing } from '../../theme';
import { UserProfileComponent } from '../user-profile/user-profile.component';

const PREFIX = 'HeaderComponent';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  appBar: `${PREFIX}-appBar`,
  logohfmyr: `${PREFIX}-logohfmyr`,
  shrinkHeader: `${PREFIX}-shrinkHeader`,
  logoImage: `${PREFIX}-logoImage`,
  outerContainer: `${PREFIX}-outerContainer`,
  leftContainer: `${PREFIX}-leftContainer`,
  rightContainer: `${PREFIX}-rightContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    marginBottom: theme.spacing(6),
  },

  [`& .${classes.toolbar}`]: {
    padding: 0,
    margin: theme.spacing(3),
  },

  [`& .${classes.appBar}`]: {
    height: '10px',
  },

  [`& .${classes.logohfmyr}`]: {
    height: '24px',
    width: '24px',

    marginLeft: '8px',
    marginTop: '8px',
    cursor: 'pointer',
  },

  [`& .${classes.shrinkHeader}`]: {
    height: '40px',
    width: '40px',
    '&.MuiPaper-root': {
      borderWidth: '0px',
      boxShadow: 'none',
      backgroundColor: irisCustomColors.irisBlueGray,
    },

    margin: `${theme.spacing(irisSpacing.structure.p)} 9%`,
    marginTop: '10px',
  },

  [`& .${classes.logoImage}`]: {
    marginLeft: theme.spacing(9),
    marginRight: theme.spacing(8),
  },

  [`& .${classes.outerContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },

  [`& .${classes.leftContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.rightContainer}`]: {
    marginRight: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
  },
}));

export const HeaderComponent = () => {
  const { t } = useTranslation(['common']);
  const [hideHeader, setHideHeader] = useState<boolean>(true);
  const expandHeaderTime = 800;

  const handleScroll = useCallback(() => {
    setHideHeader(true);
  }, []);

  const handleClick = () => {
    window.removeEventListener('scroll', handleScroll);
    setHideHeader(false);
    setTimeout(() => {
      window.addEventListener('scroll', handleScroll);
    }, expandHeaderTime);
  };

  const getHeaderCollapsed = () => {
    return (
      <Slide in={hideHeader} timeout={3000} style={{ transformOrigin: 'top' }}>
        <AppBar className={classes.appBar}>
          <Paper className={classes.shrinkHeader}>
            <img
              data-testid={'logohfmyr'}
              onClick={handleClick}
              className={classes.logohfmyr}
              src={logohfmyer}
              alt={t('common:iris')}
            />
          </Paper>
        </AppBar>
      </Slide>
    );
  };

  const getHeaderExpaned = () => {
    return (
      <Slide in={!hideHeader} timeout={expandHeaderTime} style={{ transformOrigin: 'top' }}>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <Box className={classes.outerContainer}>
              <Box className={classes.leftContainer}>
                <NavLink to={AppRoutePath.root}>
                  <img className={classes.logoImage} src={logo} alt={t('common:irisProduction')} />
                </NavLink>
              </Box>
              <Box className={classes.rightContainer}>
                <UserProfileComponent />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
    );
  };

  return (
    <Root className={classes.root}>
      {hideHeader ? <></> : <Toolbar className={classes.toolbar} />}
      {getHeaderCollapsed()}
      {getHeaderExpaned()}
    </Root>
  );
};
