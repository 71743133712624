import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BackToOverviewLink } from '../../components/back-link/back-link.component';
import { dataTableClasses, DataTableRoot } from '../../components/data-table/data-table.styles';
import { PasswordInputComponent } from '../../components/form/password-input/password-input.component';
import { InfoboxComponent } from '../../components/infobox/infobox.component';
import { ContainerInside, ContainerOutside } from '../../components/structure';
import {
  changePassword,
  changePasswordSuccessfulSelector,
  clearChangePasswordSuccessful,
  fetchProfileInfo,
  profileInfoSelector,
} from '../../store';
import { irisSpacing } from '../../theme';

interface FormData {
  password: string;
  passwordConfirmation: string;
  passwordsMismatchError: string;
  currentPassword: string;
}

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'login', 'form', 'common']);
  const methods = useForm<FormData>();
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    control,
  } = methods;

  const profileInfo = useSelector(profileInfoSelector);
  const changePasswordSuccessful = useSelector(changePasswordSuccessfulSelector);

  useEffect(() => {
    dispatch(fetchProfileInfo());
    return () => {
      dispatch(clearChangePasswordSuccessful());
    };
  }, [dispatch]);

  const onSubmit = handleSubmit((formData: FormData) => {
    reset({
      password: undefined,
      passwordConfirmation: undefined,
      passwordsMismatchError: undefined,
      currentPassword: undefined,
    });
    dispatch(
      changePassword(formData.currentPassword, formData.password, formData.passwordConfirmation)
    );
  });

  const validatePasswordsMatch = () => {
    const formData = getValues();
    return formData.password === formData.passwordConfirmation;
  };

  return (
    <Box>
      <DataTableRoot>
        <BackToOverviewLink />
        <Box>
          {profileInfo && (
            <>
              <Typography variant="h1">
                {t('login:welcomeUser', {
                  firstName: profileInfo.firstName,
                  lastName: profileInfo.lastName,
                })}
              </Typography>
              <ContainerOutside>
                <Paper>
                  <ContainerInside>
                    <Typography variant="h3">{t('login:yourData')}</Typography>
                    <table className={dataTableClasses.dataTable}>
                      <tbody>
                        <tr>
                          <td>{t('data:user.username')}:</td>
                          <td>{profileInfo.username}</td>
                        </tr>
                        <tr>
                          <td>{t('data:user.firstAndLastName')}:</td>
                          <td>{`${profileInfo.firstName} ${profileInfo.lastName}`}</td>
                        </tr>
                        <tr>
                          <td>{t('data:user.workspace')}:</td>
                          <td>{profileInfo.workspace}</td>
                        </tr>
                        <tr>
                          <td>{t('data:user.role')}:</td>
                          <td>
                            {t([
                              `data:user.userRoles.${profileInfo.role}`,
                              `data:user.userRoles.notFound`,
                            ])}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ContainerInside>
                </Paper>
              </ContainerOutside>
              <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                  <ContainerOutside>
                    <Paper>
                      <ContainerInside>
                        <Typography variant="h3">{t('login:changePassword')}</Typography>
                        <Grid container spacing={irisSpacing.input.space}>
                          <Grid item xs={12} md={6} lg={4}>
                            <Grid container spacing={irisSpacing.input.space}>
                              <Grid item xs={12}>
                                <PasswordInputComponent
                                  name="currentPassword"
                                  label={t('login:currentPassword')}
                                  testId="current-password-input"
                                  autocomplete="current-password"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <PasswordInputComponent
                                  name="password"
                                  label={t('login:newPassword')}
                                  testId="password-input"
                                  autocomplete="new-password"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <PasswordInputComponent
                                  name="passwordConfirmation"
                                  label={t('login:passwordConfirmation')}
                                  testId="password-confirmation-input"
                                  autocomplete="new-password"
                                />
                              </Grid>
                              {changePasswordSuccessful === false && (
                                <Grid item xs={12}>
                                  <InfoboxComponent
                                    type="error"
                                    headline={t('login:passwordCouldNotBeChanged')}
                                  />
                                </Grid>
                              )}
                              {changePasswordSuccessful === true && (
                                <Grid item xs={12}>
                                  <InfoboxComponent
                                    type="success"
                                    headline={t('login:passwordCouldBeChanged')}
                                  />
                                </Grid>
                              )}
                              <Controller
                                name="passwordsMismatchError"
                                control={control}
                                defaultValue={' '}
                                render={({ field }) => <input {...field} type="hidden" />}
                                rules={{
                                  validate: () =>
                                    validatePasswordsMatch() ||
                                    (t('login:passwordsMismatch') as string),
                                }}
                              />

                              {errors.passwordsMismatchError &&
                                errors.passwordsMismatchError.message && (
                                  <Grid item xs={12}>
                                    <InfoboxComponent
                                      type="error"
                                      headline={errors.passwordsMismatchError.message}
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ContainerInside>
                    </Paper>
                  </ContainerOutside>
                  <Box display="flex" justifyContent="flex-end" mt={irisSpacing.button.mt}>
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid="submit-btn"
                      type="submit"
                    >
                      {t('login:changePassword')}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </>
          )}
        </Box>
      </DataTableRoot>
    </Box>
  );
};
