import { produce } from 'immer';
import { Action } from 'redux';

import { totalPages } from '../../helper';
import {
  ProductionRun,
  ProductionRunDisruption,
  ProductionRunStatus,
  ToleranceResult,
} from '../../model';
import { LinesActionType } from '../lines';
import {
  ProductionOrdersActionType,
  startProductionOrderSuccess,
} from '../production-orders/production-orders.actions';

import {
  ProductionRunsActionType,
  fetchProductionRunsSuccess,
  fetchProductionRunSuccess,
  confirmCheckForProductionRunSuccess,
  skipCheckForProductionRunSuccess,
  startDisruptionSuccess,
  resolveDisruptionSuccess,
  stopProductionRunSuccess,
  calcFertigPackTolerancesSuccess,
  setProductionRunsCurrentPageIndex,
  reloadProductionRunsSilentSuccess,
  pauseProductionRunSuccess,
  continueProductionRunSuccess,
} from './production-runs.actions';
import {
  ProductionRunsWebsocketActionType,
  changedProductionRun as changedProductionRunAction,
  changedProductionTestRun as changedProductionTestRunAction,
  finishedProductionRun as finishedProductionRunAction,
} from './production-runs.websocket-actions';

export interface ProductionRunsState {
  productionRuns: { [id: string]: ProductionRun };
  itemsTotalCount?: number;
  currentPageIndex?: number;
  productionRunsFetched?: boolean;
  currentProductionRun?: ProductionRun;
  isSkipCheckDialogOpen: boolean;
  isDisruptionDialogOpen: boolean;
  currentFertigPackVTolerances?: ToleranceResult;
  isHelpDialogOpen?: boolean;
  isCompetingOrdersDialogOpen?: boolean;
  silentReload?: boolean;
  isAppendDialogOpen: boolean;
}

export const getInitialState = (): ProductionRunsState => {
  return {
    productionRuns: {},
    isSkipCheckDialogOpen: false,
    isDisruptionDialogOpen: false,
    isAppendDialogOpen: false,
  };
};

const updateCurrentPageIndex = (draftState: ProductionRunsState) => {
  const maxPages = totalPages(draftState.itemsTotalCount);
  const maxPageIndex = maxPages - 1;
  if (maxPageIndex < 0) {
    delete draftState.currentPageIndex;
  } else if (draftState.currentPageIndex && draftState.currentPageIndex > maxPageIndex)
    draftState.currentPageIndex = maxPageIndex;
};

const setProductionRuns = (
  draftState: ProductionRunsState,
  action: ReturnType<typeof fetchProductionRunsSuccess | typeof reloadProductionRunsSilentSuccess>
) => {
  const { productionRuns, total } = action.payload;
  const productionRunsDictionary = productionRuns.reduce(
    (obj: { [id: string]: ProductionRun }, productionRun) => {
      obj[productionRun.id] = {
        ...productionRun,
      };
      return obj;
    },
    {}
  );
  draftState.productionRuns = productionRunsDictionary;
  draftState.itemsTotalCount = total;
};

const setCurrentProductionRun = (draftState: ProductionRunsState, productionRun: ProductionRun) => {
  if (productionRun.status === ProductionRunStatus.Done)
    deleteCurrentProductionRun(draftState, productionRun);
  else draftState.currentProductionRun = productionRun;
};

const updateProductionRun = (draftState: ProductionRunsState, productionRun: ProductionRun) => {
  if (productionRun.status === ProductionRunStatus.Done)
    deleteProductionRun(draftState, productionRun);
  else if (draftState.productionRuns[productionRun.id])
    draftState.productionRuns[productionRun.id] = productionRun;
};

const updateCurrentProductionRun = (
  draftState: ProductionRunsState,
  productionRun: ProductionRun
) => {
  if (draftState.currentProductionRun?.id === productionRun.id)
    draftState.currentProductionRun = productionRun;
};

const deleteProductionRun = (draftState: ProductionRunsState, productionRun: ProductionRun) => {
  if (draftState.productionRuns[productionRun.id])
    delete draftState.productionRuns[productionRun.id];
};

const deleteCurrentProductionRun = (
  draftState: ProductionRunsState,
  productionRun: ProductionRun
) => {
  if (draftState.currentProductionRun?.id === productionRun.id)
    delete draftState.currentProductionRun;
};

const setProductionRunDisruption = (
  draftState: ProductionRunsState,
  productionRunId: string,
  disruption: ProductionRunDisruption
) => {
  if (draftState.productionRuns[productionRunId]) {
    draftState.productionRuns[productionRunId].disrupted = true;
    draftState.productionRuns[productionRunId].disruptions =
      draftState.productionRuns[productionRunId].disruptions || [];
    draftState.productionRuns[productionRunId].disruptions.unshift(disruption);
  }
  if (draftState.currentProductionRun?.id === productionRunId) {
    draftState.currentProductionRun.disrupted = true;
    draftState.currentProductionRun.disruptions = draftState.currentProductionRun.disruptions || [];
    draftState.currentProductionRun.disruptions.unshift(disruption);
  }
};

const resolveProductionRunDisruption = (
  draftState: ProductionRunsState,
  productionRun: ProductionRun
) => {
  if (draftState.productionRuns[productionRun.id]) {
    draftState.productionRuns[productionRun.id].disrupted = false;
    draftState.productionRuns[productionRun.id].disruptions.shift();
  }
  if (draftState.currentProductionRun?.id === productionRun.id) {
    draftState.currentProductionRun.disrupted = false;
    draftState.currentProductionRun.disruptions.shift();
  }
};

const clearFertigPackTolerancesInState = (previousState: ProductionRunsState) => {
  return produce(previousState, (draftState) => {
    delete draftState.currentFertigPackVTolerances;
  });
};

export const productionRunsReducer = (
  previousState: ProductionRunsState = getInitialState(),
  action: Action<
    | ProductionRunsActionType
    | ProductionRunsWebsocketActionType
    | ProductionOrdersActionType
    | LinesActionType
  >
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ProductionRunsActionType.productionRunsFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.productionRunsFetched = false;
      });
      break;

    case ProductionRunsActionType.productionRunsFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        setProductionRuns(draftState, action as ReturnType<typeof fetchProductionRunsSuccess>);
        draftState.productionRunsFetched = true;
      });
      break;

    case ProductionRunsActionType.productionRunsFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.productionRunsFetched = true;
      });
      break;

    case ProductionRunsActionType.productionRunsReloadSilentSuccess:
      nextState = produce(previousState, (draftState) => {
        setProductionRuns(
          draftState,
          action as ReturnType<typeof reloadProductionRunsSilentSuccess>
        );
        draftState.silentReload = false;
        updateCurrentPageIndex(draftState);
      });
      break;

    case ProductionRunsActionType.productionRunsReloadSilentFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.silentReload = false;
      });
      break;

    case ProductionRunsActionType.productionRunFetchSuccess:
      const fetchSuccessAction = action as ReturnType<typeof fetchProductionRunSuccess>;
      nextState = produce(previousState, (draftState) => {
        const productionRun = fetchSuccessAction.payload.productionRun;
        updateProductionRun(draftState, productionRun);
        setCurrentProductionRun(draftState, productionRun);
      });
      break;

    case ProductionRunsActionType.productionRunStopSuccess:
    case ProductionRunsActionType.productionRunCheckConfirmSuccess:
      nextState = produce(previousState, (draftState) => {
        const productionRunAction = action as ReturnType<
          typeof stopProductionRunSuccess | typeof confirmCheckForProductionRunSuccess
        >;
        const { productionRun } = productionRunAction.payload;
        if (productionRun.status === ProductionRunStatus.Done) {
          deleteProductionRun(draftState, productionRun);
          deleteCurrentProductionRun(draftState, productionRun);
        } else {
          updateProductionRun(draftState, productionRun);
          updateCurrentProductionRun(draftState, productionRun);
        }
      });
      break;

    case ProductionRunsActionType.productionRunPauseSuccess:
    case ProductionRunsActionType.productionRunContinueSuccess:
      nextState = produce(previousState, (draftState) => {
        const productionRunAction = action as ReturnType<
          typeof pauseProductionRunSuccess | typeof continueProductionRunSuccess
        >;
        const { productionRun } = productionRunAction.payload;
        updateProductionRun(draftState, productionRun);
        updateCurrentProductionRun(draftState, productionRun);
      });
      break;

    case ProductionRunsActionType.productionRunDisruptionStartSuccess:
      nextState = produce(previousState, (draftState) => {
        const disruptionAction = action as ReturnType<typeof startDisruptionSuccess>;
        const { id, disruption } = disruptionAction.payload;
        setProductionRunDisruption(draftState, id, disruption);
      });
      break;

    case ProductionRunsActionType.productionRunDisruptionResolveSuccess:
      nextState = produce(previousState, (draftState) => {
        const disruptionAction = action as ReturnType<typeof resolveDisruptionSuccess>;
        const { productionRun } = disruptionAction.payload;
        resolveProductionRunDisruption(draftState, productionRun);
      });
      break;

    case ProductionRunsActionType.productionRunCheckSkipSuccess:
      const skipSuccessAction = action as ReturnType<typeof skipCheckForProductionRunSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { productionRun } = skipSuccessAction.payload;
        if (productionRun.status !== ProductionRunStatus.Done) {
          updateProductionRun(draftState, productionRun);
          updateCurrentProductionRun(draftState, productionRun);
        }
        draftState.isSkipCheckDialogOpen = false;
      });
      break;

    case ProductionRunsActionType.productionRunOpenSkipCheckDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isSkipCheckDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseSkipCheckDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isSkipCheckDialogOpen = false;
      });
      break;

    case ProductionRunsActionType.productionRunCheckOpenAppendDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isAppendDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunAppendChecksSuccess:
    case ProductionRunsActionType.productionRunCheckCloseAppendDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isAppendDialogOpen = false;
      });
      break;

    case ProductionRunsActionType.productionRunClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRun;
      });
      break;

    case ProductionRunsActionType.productionRunCalcFertigPackTolerancesSuccess:
      const tolerancesAction = action as ReturnType<typeof calcFertigPackTolerancesSuccess>;
      nextState = produce(previousState, (draftstate) => {
        draftstate.currentFertigPackVTolerances = tolerancesAction.payload.tolerances;
      });
      break;

    case ProductionRunsActionType.productionRunFertigPackTolerancesClear:
      nextState = clearFertigPackTolerancesInState(previousState);
      break;

    case ProductionRunsActionType.productionRunOpenDisruptionDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isDisruptionDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseDisruptionDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isDisruptionDialogOpen = false;
      });
      break;

    case ProductionRunsActionType.productionRunsCurrentPageSet:
      nextState = produce(previousState, (draftState) => {
        const currentPageAction = action as ReturnType<typeof setProductionRunsCurrentPageIndex>;
        draftState.currentPageIndex = currentPageAction.payload.pageIndex;
      });
      break;

    case ProductionRunsActionType.productionRunOpenHelpDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isHelpDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseHelpDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isHelpDialogOpen = false;
      });
      break;

    case ProductionRunsActionType.productionRunOpenCompetingOrdersDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isCompetingOrdersDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseCompetingOrdersDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isCompetingOrdersDialogOpen = false;
      });
      break;

    case ProductionRunsWebsocketActionType.productionRunFinished:
      nextState = produce(previousState, (draftState) => {
        const finishedAction = action as ReturnType<typeof finishedProductionRunAction>;
        const productionRun = finishedAction.payload.productionRun;
        deleteCurrentProductionRun(draftState, productionRun);
        deleteProductionRun(draftState, productionRun);
        draftState.silentReload = true;
      });
      break;

    case ProductionRunsWebsocketActionType.productionRunChanged:
    case ProductionRunsWebsocketActionType.productionTestRunChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<
          typeof changedProductionRunAction | typeof changedProductionTestRunAction
        >;
        const productionRun = changedAction.payload.productionRun;
        updateCurrentProductionRun(draftState, productionRun);
        updateProductionRun(draftState, productionRun);
        draftState.silentReload = true;
      });
      break;

    case ProductionOrdersActionType.productionOrderStartSuccess:
      nextState = produce(previousState, (draftState) => {
        const startSuccessAction = action as ReturnType<typeof startProductionOrderSuccess>;
        const { productionRun } = startSuccessAction.payload;
        updateProductionRun(draftState, productionRun);
        setCurrentProductionRun(draftState, productionRun);
      });
      break;

    case LinesActionType.lineChange:
      nextState = produce(previousState, (draftState) => {
        draftState.productionRuns = {};
        draftState.productionRunsFetched = false;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
