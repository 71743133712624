import { produce } from 'immer';
import { Action } from 'redux';

import { User } from '../../model';

import { UsersActionType, fetchUserSuccess } from './users.actions';

export interface UsersState {
  currentUser?: User;
  requestResetPasswordWasFailed?: boolean;
}

export const getInitialState = (): UsersState => {
  return {};
};

export const usersReducer = (
  previousState: UsersState = getInitialState(),
  action: Action<UsersActionType>
) => {
  let nextState;

  switch (action.type) {
    case UsersActionType.userFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentUser;
      });
      break;
    case UsersActionType.userFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchUserSuccess>;
        draftState.currentUser = fetchSuccessAction.payload.user;
      });
      break;
    case UsersActionType.userRequestResetPassword:
    case UsersActionType.userRequestResetPasswordFailedClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.requestResetPasswordWasFailed;
      });
      break;
    case UsersActionType.userRequestResetPasswordFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.requestResetPasswordWasFailed = true;
      });
      break;
    case UsersActionType.userRequestResetPasswordSuccess:
      nextState = produce(previousState, (draftState) => {
        draftState.requestResetPasswordWasFailed = false;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
