import { DeviceStatus } from '../../model';

export enum DevicesWebsocketActionType {
  deviceStatusChanged = 'device/statusChanged',
  deviceIssueOccurred = 'device/issueOccurred',
}

export const changedStaticScaleDeviceStatus = (deviceStatus: DeviceStatus) => ({
  type: DevicesWebsocketActionType.deviceStatusChanged,
  payload: { deviceStatus },
});

export const occuredStaticScaleDeviceIssue = (e: any) => ({
  type: DevicesWebsocketActionType.deviceIssueOccurred,
  payload: { e },
});
