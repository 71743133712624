import { createSelector } from 'reselect';

import { AppState } from '..';

export const weightsStateSelector = (state: AppState) => state.weights;
export const checkWeightsSelector = createSelector(
  weightsStateSelector,
  (state) => state.checkWeights
);

export const currentCheckSampleSizeSelector = createSelector(
  weightsStateSelector,
  (state) => state.currentCheckSampleSize
);

export const hasAllRequiredCheckWeightsSelector = createSelector(
  checkWeightsSelector,
  currentCheckSampleSizeSelector,
  (weights, sampleSize) => {
    let hasAllRequiredWeigths = true;
    let currentSampleIndex = 0;

    while (hasAllRequiredWeigths && currentSampleIndex < sampleSize) {
      if (weights[`weight${currentSampleIndex}`]?.weightDisplay == null) {
        hasAllRequiredWeigths = false;
      }
      currentSampleIndex++;
    }
    return hasAllRequiredWeigths;
  }
);

export const hasAllRequiredTareWeightsSelector = createSelector(
  checkWeightsSelector,
  currentCheckSampleSizeSelector,
  (weights, sampleSize) => {
    let hasAllRequiredTareWeigths = true;
    let currentSampleIndex = 0;

    while (hasAllRequiredTareWeigths && currentSampleIndex < sampleSize) {
      if (weights[`weight${currentSampleIndex}`]?.tareValue == null) {
        hasAllRequiredTareWeigths = false;
      }
      currentSampleIndex++;
    }
    return hasAllRequiredTareWeigths;
  }
);

export const areWeightsFetchedSelector = createSelector(
  weightsStateSelector,
  (state) => state.areWeightsFetched
);
