import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getFertigPackVStatistics } from '../../helper';
import { ProductionRun } from '../../model';
import { irisCustomColors } from '../../theme';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';
import { MessageFlagComponent } from '../message-flag/message-flag.component';

import { GiveAwayChartComponent } from './give-away-chart.component';
import { FlexEndContainer } from './give-away-chart.styles';

export interface OwnProps {
  productionRun: ProductionRun;
}

export const GiveAwayChartInfoComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const { productionRun } = props;
  const statistics = getFertigPackVStatistics(productionRun);

  const checkSamplesGiveAwayPercentage = () => {
    return (
      <Typography variant="h3">
        {statistics?.checkSamplesGiveAwayRelative ?? 0}
        {'%'}
      </Typography>
    );
  };

  return (
    <>
      <GridContainer level={GridLevel.Graphic} data-testid="giveAwayChartInfoComponent">
        <GridItem>
          <GridContainer>
            <GridItem s={6}>
              <GridItem>
                <Typography variant="h4">
                  {t('data:productionRun.giveAwayProductionRun')}
                </Typography>
              </GridItem>
              <GridItem s={6}>
                <Typography data-testid="samplesAmountGiveAway">
                  {`${t('data:check.samplesAmount')} `}
                  {statistics?.checkSamplesDone ?? 0}
                </Typography>
              </GridItem>
            </GridItem>
            <GridItem s={6}>
              {statistics?.checkSamplesGiveAwayRelative &&
              statistics.checkSamplesGiveAwayRelative < 0 ? (
                <MessageFlagComponent
                  productionRun={productionRun}
                  customContent={checkSamplesGiveAwayPercentage()}
                  specifiedFlagColor={irisCustomColors.irisYellow}
                  specifiedLabelColor={irisCustomColors.irisBlack}
                />
              ) : (
                <FlexEndContainer>{checkSamplesGiveAwayPercentage()}</FlexEndContainer>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>
          <GiveAwayChartComponent productionRun={productionRun} />
        </GridItem>
      </GridContainer>
    </>
  );
};
