import { Grid } from '@mui/material';
import React from 'react';

import { responsiveness } from '../../theme';

export enum GridLevel {
  Page = 1,
  InfoPaper = 2,
  CheckPaper = 3,
  Collective = 4,
  Graphic = 5,
}

export interface OwnProps {
  children: React.ReactNode;
  level?: GridLevel;
  'data-testid'?: string;
}

const getSpacing = (level?: GridLevel) => {
  let columnSpacing;
  let rowSpacing;

  switch (level) {
    case GridLevel.InfoPaper:
      columnSpacing = responsiveness.grid.infoPaper.space.x;
      rowSpacing = responsiveness.grid.infoPaper.space.y;
      break;
    case GridLevel.CheckPaper:
      columnSpacing = responsiveness.grid.checkPaper.space.x;
      rowSpacing = responsiveness.grid.checkPaper.space.y;
      break;
    case GridLevel.Collective:
      columnSpacing = responsiveness.grid.collective.space.x;
      rowSpacing = responsiveness.grid.collective.space.y;
      break;
    case GridLevel.Graphic:
      columnSpacing = responsiveness.grid.graphic.space.x;
      rowSpacing = responsiveness.grid.graphic.space.y;
      break;
    case GridLevel.Page:
    default:
      columnSpacing = responsiveness.grid.page.space.x;
      rowSpacing = responsiveness.grid.page.space.y;
  }

  return { columnSpacing, rowSpacing };
};

export const GridContainer = (props: OwnProps) => {
  const { children, level, ...rest } = props;
  const { columnSpacing, rowSpacing } = getSpacing(level);

  return (
    <Grid container columnSpacing={columnSpacing} rowSpacing={rowSpacing} {...rest}>
      {children}
    </Grid>
  );
};
