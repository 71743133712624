import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CheckAttributeType } from '../../../model';
import {
  checkExecutionSamplesSelector,
  confirmCheckForProductionRun,
  sendBarcodeSample,
  fetchCheckExecution,
  checkExecutionSamplesCompleteSelector,
  initCheckExecutionSamples,
  resetCheckExecutionSamples,
  startWsConnection,
  checkExecutionsWebsocketConfig,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { CheckAccordion } from '../../accordion/check-accordion.component';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { CheckResultIconComponent } from '../../check-result-icon/check-result-icon.component';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { CheckContainerInside, InfoHeaderContainer } from '../../structure';
import {
  CheckMeasurementTableComponent,
  MeasurementColumn,
} from '../check-measurement-table.component';
import { CheckProps } from '../check.component';
import { CheckDescriptionComponent } from '../common/check.components';

const CheckResultContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const InfoBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(irisSpacing.button.space),
}));

const initTempSamples = (sampleSize: number) => {
  const initialTempSamples: { [index: number]: string } = {};
  for (let i = 0; i < sampleSize; i++) initialTempSamples[i] = '';
  return initialTempSamples;
};

export const BarcodeCheckComponent = (props: CheckProps) => {
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();
  const { productionRun, openCheckExecution, checkAttribute } = props;
  const { id: productionRunId } = productionRun;
  const { productionRunCheckExecutionId: openCheckExecutionId } = openCheckExecution;
  const { sampleSize, barcodeDescription: description } = checkAttribute;

  const storedSamples = useSelector(checkExecutionSamplesSelector);
  const hasAllSamples = useSelector(checkExecutionSamplesCompleteSelector);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [tempSamples, setTempSamples] = useState<{ [index: number]: string }>(
    initTempSamples(sampleSize)
  );

  useEffect(() => {
    dispatch(initCheckExecutionSamples(sampleSize));
    dispatch(fetchCheckExecution(productionRunId, openCheckExecutionId));
    dispatch(startWsConnection(checkExecutionsWebsocketConfig));
    setTempSamples(initTempSamples(sampleSize));
    setSubmitted(false);
    return () => {
      dispatch(resetCheckExecutionSamples());
    };
  }, [dispatch, productionRunId, openCheckExecutionId, sampleSize]);

  useEffect(() => {
    setSubmitted(false);
  }, [storedSamples]);

  const confirmCheck = () => {
    if (hasAllSamples) {
      dispatch(
        confirmCheckForProductionRun(
          productionRun.id,
          openCheckExecution,
          CheckAttributeType.Barcode
        )
      );
    }
  };

  const confirmationHandler = () => {
    setSubmitted(true);
    confirmCheck();
  };

  const barcodeDetails = () => {
    return (
      <InfoHeaderContainer data-testid="checkInfoHeader">
        <CheckDescriptionComponent text={description} />
        {checkAttribute.barcode && (
          <Typography fontWeight={'bold'}>
            {t('data:check.targetBarcode')} {checkAttribute.barcode}
          </Typography>
        )}
      </InfoHeaderContainer>
    );
  };

  const updateTempSample = (sampleIndex: number, newValue: string) => {
    const draftState = { ...tempSamples };
    draftState[sampleIndex] = newValue;
    setTempSamples(draftState);
  };

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    sampleIndex: number
  ) => {
    updateTempSample(sampleIndex, event.target.value);
  };

  const handleFieldFocus = (sampleIndex: number) => {
    if (tempSamples[sampleIndex]) updateTempSample(sampleIndex, '');
  };

  const handleFieldLeave = (sampleIndex: number) => {
    const sampleNumber = sampleIndex + 1;
    if (tempSamples[sampleIndex])
      dispatch(
        sendBarcodeSample(
          tempSamples[sampleIndex]!,
          productionRunId,
          openCheckExecutionId,
          sampleNumber
        )
      );
  };

  const barcodeField = (sampleIndex: number) => {
    const id = `barcodeInput${sampleIndex}`;
    return (
      <>
        {storedSamples[sampleIndex] && storedSamples[sampleIndex].barcode ? (
          <Typography>{storedSamples[sampleIndex].barcode}</Typography>
        ) : (
          <TextField
            sx={{ width: '200px' }}
            key={id}
            id={id}
            name={id}
            label={t('data:check.barcode')}
            value={tempSamples[sampleIndex]}
            onChange={(e) => handleFieldChange(e, sampleIndex)}
            onFocus={() => handleFieldFocus(sampleIndex)}
            onBlur={() => handleFieldLeave(sampleIndex)}
            inputProps={{
              'data-testid': id,
            }}
          />
        )}
      </>
    );
  };

  const checkResultRating = (sampleIndex: number) => {
    return (
      <>
        {storedSamples && (
          <CheckResultContainer>
            {!!storedSamples[sampleIndex]?.checkResult && (
              <CheckResultIconComponent
                key={sampleIndex}
                checkResult={storedSamples[sampleIndex]!.checkResult!}
              />
            )}
          </CheckResultContainer>
        )}
      </>
    );
  };

  const barcodeColumn: MeasurementColumn = {
    label: t('data:check.barcodeCheck'),
    content: barcodeField,
    width: '50%',
  };

  const ratingColumn: MeasurementColumn = {
    label: t('data:check.rating'),
    content: checkResultRating,
    width: '25%',
  };

  return (
    <Paper>
      <CheckAccordion
        checkAttribute={checkAttribute}
        detailsContent={barcodeDetails()}
        productionRun={productionRun}
      />
      <CheckContainerInside>
        <CheckMeasurementTableComponent
          sampleSize={checkAttribute.sampleSize}
          columns={[barcodeColumn, ratingColumn]}
          indexColumnWidth="25%"
        />
        {submitted && !hasAllSamples && (
          <InfoBoxContainer>
            <InfoboxComponent headline={t('data:check.executeAllSamples')} type="error" />
          </InfoBoxContainer>
        )}
        <Box mt={irisSpacing.button.mt}>
          <Grid
            container
            direction="row"
            justifyContent="right"
            alignItems="center"
            spacing={irisSpacing.button.space}
          >
            <Grid item>
              <ConfirmButtonComponent handleClick={confirmationHandler} />
            </Grid>
          </Grid>
        </Box>
      </CheckContainerInside>
    </Paper>
  );
};
