import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { CheckAttributeType, ProductionRun } from '../../../model';
import { helpDialogOpenSelector } from '../../../store';
import { irisSpacing } from '../../../theme';

import { HelpInfoComponent } from './help-info.component';

const PREFIX = 'HelpDialogComponent';

const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  checkAttributeType: CheckAttributeType;
}

export const HelpDialogComponent = (props: OwnProps) => {
  const isOpen = useSelector(helpDialogOpenSelector);
  return (
    <StyledDialog open={isOpen} maxWidth="md" className={classes.dialog} data-testid="helpDialog">
      <DialogContent>
        <HelpInfoComponent {...props} />
      </DialogContent>
    </StyledDialog>
  );
};
