import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { getPixels, irisSpacing } from '../../theme';

const VerticalFlexBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const StretchContainer = styled('div')(({ theme }) => ({
  width: `calc(100% + ${getPixels(irisSpacing.container.p * 2)}px)`,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

export interface MeasurementColumn {
  label: string;
  content: (sampleIndex: number) => JSX.Element;
  width: string;
}

export interface OwnProps {
  sampleSize: number;
  columns: MeasurementColumn[];
  indexColumnWidth: string;
}

export const CheckMeasurementTableComponent = (props: OwnProps) => {
  const { sampleSize, columns, indexColumnWidth } = props;
  const { t } = useTranslation(['data']);

  return (
    <VerticalFlexBox>
      <StretchContainer>
        <TableContainer component={Paper}>
          <Table data-testid="checkMeasurementTable">
            <TableHead>
              <TableRow key={0}>
                <StyledTableCell key={0} width={indexColumnWidth}>
                  {t('data:check.sample')}
                </StyledTableCell>
                {columns.map((column, index) => {
                  return (
                    <StyledTableCell key={index + 1} width={column.width}>
                      {column.label}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(sampleSize || 1).keys()].map((sampleIndex) => {
                return (
                  <TableRow key={sampleIndex + 1} data-testid="checkTableRow">
                    <StyledTableCell key={0} width={indexColumnWidth}>
                      {sampleIndex + 1}
                    </StyledTableCell>
                    {columns.map((column, index) => {
                      return (
                        <StyledTableCell key={index + 1} width={column.width}>
                          {column.content(sampleIndex)}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StretchContainer>
    </VerticalFlexBox>
  );
};
