import { produce } from 'immer';
import { Action } from 'redux';

import { License } from '../../model';

import { LicenseActionType, fetchLicenseSuccess } from './license.actions';

export interface LicenseState {
  license?: License | false;
}

export const getInitialState = (): LicenseState => {
  return {};
};

export const licenseReducer = (
  previousState: LicenseState = getInitialState(),
  action: Action<LicenseActionType>
) => {
  let nextState;

  switch (action.type) {
    case LicenseActionType.licenseFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.license;
      });
      break;
    case LicenseActionType.licenseFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchLicenseSuccess>;
        draftState.license = fetchSuccessAction.payload.license;
      });
      break;
    case LicenseActionType.licenseFetchFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.license = false;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
