import { createSelector } from 'reselect';

import { AppState } from '..';

export const productionRunsStateSelector = (state: AppState) => state.productionRuns;
export const productionRunsListSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.productionRuns
);

export const productionRunsFetchedSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.productionRunsFetched
);

export const productionRunsTotalCountSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.itemsTotalCount || 0
);

export const productionRunsCurrentPageIndexSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentPageIndex || 0
);

export const currentProductionRunSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRun
);

export const skipCheckDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.isSkipCheckDialogOpen
);

export const fertigPackTolerancesSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentFertigPackVTolerances
);

export const disruptionDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.isDisruptionDialogOpen
);

export const helpDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.isHelpDialogOpen
);

export const competingOrdersDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.isCompetingOrdersDialogOpen
);

export const productionRunsSilentReloadSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.silentReload
);

export const appendChecksDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.isAppendDialogOpen
);
