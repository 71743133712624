import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getCheckComponent } from '../../components/checks/check.component';
import { ChecksProgressComponent } from '../../components/checks/checks-progress.component';
import { HelpDialogComponent } from '../../components/checks/fertigpackv-check/help-dialog.component';
import { CompetingOrdersDialogComponent } from '../../components/dialogs/competing-orders/competing-orders-dialog.component';
import { ViolationDialogComponent } from '../../components/fertigpackv/violation-dialog.component';
import { IssuesComponent, IssuesElementStyle } from '../../components/issue/issues.component';
import { PauseDialogComponent } from '../../components/pause-popover/pause-dialog.component';
import { ProductionRunComponent } from '../../components/production/production-run.component';
import { ContainerOutside } from '../../components/structure';
import { hasActiveTareDeterminationAfterStart, hasActiveTestRun } from '../../helper';
import { CheckAttributeType, IssueCode, OpenCheckExecution, ProductionRun } from '../../model';
import { closeSkipCheckDialog, openSkipCheckDialog } from '../../store';
import { irisSpacing } from '../../theme';

import { CheckSkipDialogComponent } from './check-skip-dialog.component';

const PREFIX = 'CheckPage';

const classes = {
  forwardLink: `${PREFIX}-forwardLink`,
  linkContainer: `${PREFIX}-linkContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.forwardLink}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.linkContainer}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  openCheckExecution: OpenCheckExecution;
  isDisruptionDialogOpen: boolean;
}

export const CheckPage = (props: OwnProps) => {
  const { productionRun, openCheckExecution } = props;
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();

  const checkAttribute = productionRun.checks.find(
    (c) => c.id === openCheckExecution.productionRunCheckId
  )!.checkAttribute;

  const testRunActive = hasActiveTestRun(productionRun);
  const tareDeterminationActiveAfterStart = hasActiveTareDeterminationAfterStart(productionRun);

  const runExecutions = productionRun.openCheckExecutions.filter(
    (c) => c.productionRunCheckCheckAttributeType !== CheckAttributeType.Tare
  );

  const checkProps = {
    ...props,
    checkAttribute,
  };

  const handleOpenSkipCheckDialog = () => {
    dispatch(openSkipCheckDialog());
  };

  const handleCloseSkipCheckDialog = () => {
    dispatch(closeSkipCheckDialog());
  };

  const scrollToMainElement = () => {
    const mainElement = document.getElementById('main');
    if (!mainElement) return;
    const yCoord = mainElement.offsetTop - irisSpacing.anchor.space;
    window.scrollTo(0, yCoord);
  };

  const checkSkipDialog = document.getElementsByClassName('CheckSkipDialogComponent-dialog')[0];

  useEffect(() => {
    scrollToMainElement();
  }, [openCheckExecution.productionRunCheckExecutionId]);

  useEffect(() => {
    if (checkSkipDialog) {
      scrollToMainElement();
    }
  });

  return (
    <Root>
      <PauseDialogComponent productionRun={productionRun} />
      <CompetingOrdersDialogComponent productionRun={productionRun} />
      <HelpDialogComponent
        productionRun={productionRun}
        checkAttributeType={checkAttribute.checkAttributeType}
      />
      {testRunActive || tareDeterminationActiveAfterStart || (
        <>
          <ViolationDialogComponent productionRun={productionRun} />
          <CheckSkipDialogComponent
            handleCancel={handleCloseSkipCheckDialog}
            productionRunId={productionRun.id}
            openCheckExecution={openCheckExecution}
          />
        </>
      )}

      <IssuesComponent
        issues={productionRun.issues}
        allowedIssueCodes={[IssueCode.FertigPackVTU1Violation, IssueCode.AverageWeightBelowNominal]}
        iconSize={'medium'}
        elementStyle={IssuesElementStyle.sticky}
      />
      <div id="main">
        <ProductionRunComponent productionRun={productionRun} />
        <ContainerOutside>{getCheckComponent(checkProps)}</ContainerOutside>
        {testRunActive || tareDeterminationActiveAfterStart || (
          <>
            <ContainerOutside>
              <ChecksProgressComponent
                executed={productionRun.checksDone}
                total={productionRun.checksDone + runExecutions.length}
              />
            </ContainerOutside>
            <Box className={classes.linkContainer} mt={3}>
              <Link
                className={classes.forwardLink}
                component="button"
                variant="body2"
                underline="none"
                onClick={() => handleOpenSkipCheckDialog()}
              >
                {t('data:check.skipCheck')}
                <ArrowForward />
              </Link>
            </Box>
          </>
        )}
      </div>
    </Root>
  );
};
