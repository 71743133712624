import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BackToOverviewLink } from '../../components/back-link/back-link.component';
import { DisruptionButton } from '../../components/buttons/buttons.component';
import { DisruptionDialogComponent } from '../../components/disruption/disruption-dialog.component';
import { GridContainer } from '../../components/grid/grid.container';
import { GridItem } from '../../components/grid/grid.item';
import { hasActiveTestRun } from '../../helper';
import {
  clearProductionRun,
  closeDisruptionDialog,
  currentProductionRunSelector,
  disruptionDialogOpenSelector,
  fetchProductionRun,
  openDisruptionDialog,
  productionRunsListSelector,
  productionRunsWebsocketConfig,
  resolveDisruption,
  startDisruption,
  startWsConnection,
  stopProductionRun,
} from '../../store';
import { CheckPage } from '../check/check.page';

import { ProductionRunPage } from './production-run.page';

const PREFIX = 'ProductionRunContentComponent';

const classes = {
  noProductionRun: `${PREFIX}-noProductionRun`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.noProductionRun}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '160px',
  },
}));

const BackLinkContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  height: '100%',
  display: 'flex',
}));

const DisruptionButtonContainer = styled(BackLinkContainer)(({ theme }) => ({
  justifyContent: 'flex-end',
}));

export const ProductionRunContentComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const { id } = useParams<{ id: string }>();
  const productionRuns = useSelector(productionRunsListSelector);
  const productionRunPreloaded = id ? productionRuns[id] : undefined;
  const productionRun = useSelector(currentProductionRunSelector) || productionRunPreloaded;
  const isDisruptionDialogOpen = useSelector(disruptionDialogOpenSelector);
  const disrupted = productionRun && productionRun.disrupted;
  const nextOpenCheckExecution =
    productionRun &&
    productionRun.openCheckExecutions &&
    productionRun.openCheckExecutions.length > 0
      ? productionRun.openCheckExecutions[0]
      : undefined;

  const testRunActive = productionRun && hasActiveTestRun(productionRun);

  useEffect(() => {
    dispatch(startWsConnection(productionRunsWebsocketConfig));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductionRun(id));
    return () => {
      dispatch(clearProductionRun());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (disrupted) dispatch(openDisruptionDialog());
    else dispatch(closeDisruptionDialog());
  }, [dispatch, disrupted]);

  const startDisruptionForProductionRun = () => {
    productionRun && dispatch(startDisruption(productionRun.id));
  };

  const resolveDisruptionForProductionRun = (reason: string, deviceId?: string) => {
    productionRun && dispatch(resolveDisruption(productionRun.id, reason, deviceId));
  };

  const stopProductionOrderRun = (reason?: string, deviceId?: string) => {
    productionRun && dispatch(stopProductionRun(productionRun.id, reason, disrupted, deviceId));
  };

  let content;

  if (productionRun && nextOpenCheckExecution) {
    content = (
      <CheckPage
        productionRun={productionRun}
        openCheckExecution={nextOpenCheckExecution}
        isDisruptionDialogOpen={isDisruptionDialogOpen}
      />
    );
  } else if (productionRun) {
    content = <ProductionRunPage productionRun={productionRun} />;
  } else {
    content = (
      <>
        <Box className={classes.noProductionRun}>
          <Typography variant="h1">{t('data:productionRun.noProductionRun')}</Typography>
        </Box>
      </>
    );
  }

  return (
    <Root>
      {productionRun && (
        <DisruptionDialogComponent
          handleResolve={resolveDisruptionForProductionRun}
          handleStop={stopProductionOrderRun}
          productionRun={productionRun}
        />
      )}
      {!isDisruptionDialogOpen && (
        <GridContainer>
          <GridItem s={6}>
            <BackLinkContainer>
              <BackToOverviewLink />
            </BackLinkContainer>
          </GridItem>

          {productionRun && !testRunActive && (
            <GridItem s={6}>
              <DisruptionButtonContainer>
                <DisruptionButton
                  variant="outlined"
                  onClick={startDisruptionForProductionRun}
                  startIcon={<WarningIcon />}
                >
                  {t('data:disruption.startDisruption')}
                </DisruptionButton>
              </DisruptionButtonContainer>
            </GridItem>
          )}
        </GridContainer>
      )}
      {content}
    </Root>
  );
};
