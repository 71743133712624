import { ProductionRunCheckExecution } from '../../model';

export enum CheckExecutionsWebsocketActionType {
  checkExecutionChanged = 'checkExecution/changed/',

  checkExecutionIssueOccurred = 'checkExecution/issueOccurred/',
}

export const changedCheckExecution = (checkExecution: ProductionRunCheckExecution) => ({
  type: CheckExecutionsWebsocketActionType.checkExecutionChanged,
  payload: { checkExecution },
});

export const issueOccurredCheckExecution = (e: any) => ({
  type: CheckExecutionsWebsocketActionType.checkExecutionIssueOccurred,
  payload: { e },
});
