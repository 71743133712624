import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { ProductionRun } from '../../model';
import { irisSpacing } from '../../theme';

import { AverageWeightViolationInfoComponent } from './average-weight-violation-info.component';

const PREFIX = 'TU1ViolationDialogComponent';

const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const AverageWeightViolationDialogComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const isAverageWeightPopOverOpen =
    !!productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation &&
    !productionRun.fertigPackVData.hasCriticalTU1Violation;

  return (
    <StyledDialog
      open={isAverageWeightPopOverOpen}
      maxWidth="md"
      className={classes.dialog}
      data-testid="avgWeightDialog"
    >
      <DialogContent>
        <AverageWeightViolationInfoComponent />
      </DialogContent>
    </StyledDialog>
  );
};
