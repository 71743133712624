import { WebsocketConfig } from '../websockets';

import {
  changedCheckExecution,
  issueOccurredCheckExecution,
} from './check-executions.websocket-actions';

export const checkExecutionsWebsocketConfig: WebsocketConfig = {
  url: 'production/executions',
  actions: [
    {
      methodName: 'ProductionRunCheckExecutionChanged',
      actionCreator: changedCheckExecution,
    },
  ],
};

export const checkExecutionIssuesWebsocketConfig: WebsocketConfig = {
  url: 'production/execution/issues',
  actions: [
    {
      methodName: 'ProductionRunCheckExecutionIssueOccurred',
      actionCreator: issueOccurredCheckExecution,
    },
  ],
};
