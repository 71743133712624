import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBlockingOrderNumber } from '../../../helper';
import { ProductionRun } from '../../../model';
import {
  closeCompetingOrdersDialog,
  competingOrdersDialogOpenSelector,
  hasAllRequiredCheckWeightsSelector,
  openCompetingOrdersDialog,
  scalesStatusSelector,
} from '../../../store';
import { irisSpacing } from '../../../theme';

import { CompetingOrdersInfoComponent } from './competing-orders-info.component';

const PREFIX = 'DialogComponent';

const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(irisSpacing.container.p),
    },
  },
}));
export interface OwnProps {
  productionRun: ProductionRun;
}

export const CompetingOrdersDialogComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const deviceId = productionRun.scaleDevice?.deviceId;
  const dispatch = useDispatch();
  const isOpen = useSelector(competingOrdersDialogOpenSelector);
  const deviceStatus = useSelector(scalesStatusSelector);
  const currentDeviceStatus = deviceId ? deviceStatus[deviceId] : undefined;
  const [blockingOrderNumber, setBlockingOrderNumber] = useState<string>('');
  const hasAllRequiredWeights = useSelector(hasAllRequiredCheckWeightsSelector);

  useEffect(() => {
    if (currentDeviceStatus) {
      const blockingOrderNumber = getBlockingOrderNumber(productionRun, currentDeviceStatus);
      if (blockingOrderNumber !== undefined && !hasAllRequiredWeights) {
        setBlockingOrderNumber(blockingOrderNumber);
        dispatch(openCompetingOrdersDialog());
      } else {
        dispatch(closeCompetingOrdersDialog());
      }
    }
  }, [currentDeviceStatus, dispatch, hasAllRequiredWeights, productionRun]);

  return (
    <StyledDialog open={!!isOpen} maxWidth="md" className={classes.dialog} data-testid="dialog">
      <DialogContent>
        <CompetingOrdersInfoComponent
          productionRun={props.productionRun}
          blockingOrderNumber={blockingOrderNumber}
        />
      </DialogContent>
    </StyledDialog>
  );
};
