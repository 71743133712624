export const IMAGE_HEIGHT_LIST = 150;
export const IMAGE_WIDTH_LIST = 200;
export const IMAGE_HEIGHT_CHECK = 300;
export const IMAGE_WIDTH_CHECK = 400;
export const IMAGE_RATIO = 4 / 3;
export const WEIGHT_ENTITY_GRAM = 'g';
export const WEIGHT_ENTITY_KILOGRAM = 'kg';
export const VOLUME_ENTITY_MILLILITER = 'ml';
export const VOLUME_ENTITIY_LITER = 'l';
export const TEMPERATURE_ENTITY = '°C';
export const MAX_INTEGER = 2147483647;
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const DEFAULT_LINE_ID = '-1';
export const DEFAULT_AVERAGE_WEIGHT_MODE_RUN_PAGE = 2;
