import { Line, ProductionLine, Unit } from '.';

export interface Article {
  id: string;
  name: string;
  nameSuffix?: string;
  articleNumber: string;
  productionLine?: Line;
  nominalValue?: number;
  volumeValue?: number;
  densityValue?: number;
  tareMode?: TareMode;
  tareValue?: number;
  lowerTolerance?: number;
  upperTolerance?: number;
  thumbnailUrl?: string;
  imageUrl?: string;
  lastModified: string;
  tareDeterminationMode?: TareDeterminationMode;
  measurementUnit?: Unit;
}

export interface ProductionRunArticle extends Omit<Article, 'id' | 'productionLine'> {
  productionLine: ProductionLine;
}

export enum TareMode {
  TarePreset = 1,
  TareDetermination = 2,
  IndividualTare = 3,
}

export enum TareDeterminationMode {
  AfterStart = 1,
  BeforeCheck = 2,
}
