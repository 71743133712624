import { produce } from 'immer';
import { Action } from 'redux';

import {
  CheckAttributeType,
  ProductionRunCheckExecutionSample,
  ProductionRunCheckExecutionSampleResult,
} from '../../model';

import {
  CheckExecutionsActionType,
  sendBarcodeSampleSuccess,
  fetchCheckExecution,
  fetchCheckExecutionSuccess,
  initCheckExecutionSamples,
} from './check-executions.actions';
import {
  changedCheckExecution,
  CheckExecutionsWebsocketActionType,
} from './check-executions.websocket-actions';

export interface CheckExecutionsState {
  currentCheckExecutionId?: string;
  sampleSize: number;
  samples: { [id: number]: ProductionRunCheckExecutionSample };
}

export const getInitialState = (): CheckExecutionsState => ({
  sampleSize: 1,
  samples: {},
});

const mapSampleResult = (
  sampleNumber: number,
  sample: ProductionRunCheckExecutionSampleResult,
  checkAttributeType: CheckAttributeType
) => {
  let entry: ProductionRunCheckExecutionSample = { sampleNumber, checkResult: sample.checkResult };

  switch (checkAttributeType) {
    case CheckAttributeType.Barcode:
      entry.barcode = sample.resultValue;
  }

  return entry as ProductionRunCheckExecutionSample;
};

const setSamples = (
  previousState: CheckExecutionsState,
  draftState: CheckExecutionsState,
  samples: ProductionRunCheckExecutionSample[] | undefined
) => {
  if (samples && samples.length <= previousState.sampleSize) {
    draftState.samples = {};
    samples.map((sample) => {
      draftState.samples[sample.sampleNumber - 1] = sample;
      return draftState;
    });
  }
};

export const checkExecutionsReducer = (
  previousState: CheckExecutionsState = getInitialState(),
  action: Action<CheckExecutionsActionType | CheckExecutionsWebsocketActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case CheckExecutionsActionType.checkExecutionSamplesInit:
      const initAction = action as ReturnType<typeof initCheckExecutionSamples>;
      nextState = produce(previousState, (draftState) => {
        const { sampleSize } = initAction.payload;
        draftState.sampleSize = sampleSize;
        draftState.samples = {};
      });
      break;

    case CheckExecutionsActionType.checkExecutionSamplesReset:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentCheckExecutionId;
        draftState.sampleSize = 1;
        draftState.samples = {};
      });
      break;

    case CheckExecutionsActionType.checkExecutionFetch:
      const fetchSamplesAction = action as ReturnType<typeof fetchCheckExecution>;
      nextState = produce(previousState, (draftState) => {
        draftState.currentCheckExecutionId = fetchSamplesAction.payload.executionId;
      });
      break;

    case CheckExecutionsActionType.checkExecutionFetchSuccess:
      const fetchSamplesSuccessAction = action as ReturnType<typeof fetchCheckExecutionSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { checkExecution } = fetchSamplesSuccessAction.payload;
        const { id, samples } = checkExecution;

        if (id === draftState.currentCheckExecutionId) {
          setSamples(previousState, draftState, samples);
        }
      });
      break;

    case CheckExecutionsActionType.barcodeSampleSendSuccess:
      const addSuccessAction = action as ReturnType<typeof sendBarcodeSampleSuccess>;
      nextState = produce(previousState, (draftState) => {
        const { sampleNumber, sampleResult } = addSuccessAction.payload;

        if (sampleNumber && sampleNumber <= previousState.sampleSize) {
          draftState.samples[sampleNumber - 1] = mapSampleResult(
            sampleNumber,
            sampleResult,
            CheckAttributeType.Barcode
          );
        }
      });
      break;

    case CheckExecutionsWebsocketActionType.checkExecutionChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<typeof changedCheckExecution>;
        const { checkExecution } = changedAction.payload;
        const { id, samples } = checkExecution;

        if (id === draftState.currentCheckExecutionId) {
          setSamples(previousState, draftState, samples);
        }
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
