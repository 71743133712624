import { produce } from 'immer';
import { Action } from 'redux';

import { User, ProfileInfo } from '../../model';

import {
  AuthenticationActionType,
  loginSuccess,
  initLogin,
  loginFailure,
  fetchProfileInfoSuccess,
} from './authentication.actions';

export interface AuthenticationState {
  loggedInUser?: User;
  loginFailed: boolean;
  undefinedError?: boolean;
  insufficientUserRole?: boolean;
  changePasswordAfterResetFailed?: boolean;
  changePasswordAfterReset: boolean;
  changePasswordSuccessful?: boolean;
  profileInfo?: ProfileInfo;
}

export const getInitialState = (): AuthenticationState => {
  return { loginFailed: false, changePasswordAfterReset: true };
};

export const authenticationReducer = (
  previousState: AuthenticationState = getInitialState(),
  action: Action<AuthenticationActionType>
) => {
  let nextState;

  switch (action.type) {
    case AuthenticationActionType.login:
    case AuthenticationActionType.loginFailureClear:
      nextState = produce(previousState, (draftState) => {
        draftState.loginFailed = false;
        delete draftState.undefinedError;
        delete draftState.insufficientUserRole;
      });
      break;
    case AuthenticationActionType.loginInit:
    case AuthenticationActionType.loginSuccess:
      nextState = produce(previousState, (draftState) => {
        const loginAction = action as ReturnType<typeof loginSuccess | typeof initLogin>;
        draftState.loggedInUser = loginAction.payload.user;
        draftState.changePasswordAfterReset = loginAction.payload.changePassword;
      });
      break;
    case AuthenticationActionType.logout:
      nextState = produce(previousState, (draftState) => {
        delete draftState.loggedInUser;
      });
      break;
    case AuthenticationActionType.loginFailure:
      const loginAction = action as ReturnType<typeof loginFailure>;
      const error = loginAction.payload.e;
      nextState = produce(previousState, (draftState) => {
        if (error.status && error.status === 401) draftState.loginFailed = true;
        else draftState.undefinedError = true;
      });
      break;
    case AuthenticationActionType.loginRoleInsufficient:
      nextState = produce(previousState, (draftState) => {
        draftState.insufficientUserRole = true;
      });
      break;
    case AuthenticationActionType.changePasswordAfterReset:
    case AuthenticationActionType.changePasswordAfterResetFailureClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.changePasswordAfterResetFailed;
      });
      break;
    case AuthenticationActionType.changePasswordAfterResetSuccess:
      nextState = produce(previousState, (draftState) => {
        draftState.changePasswordAfterReset = false;
      });
      break;
    case AuthenticationActionType.changePasswordAfterResetFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.changePasswordAfterResetFailed = true;
      });
      break;
    case AuthenticationActionType.changePassword:
    case AuthenticationActionType.changePasswordSuccessfulClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.changePasswordSuccessful;
      });
      break;
    case AuthenticationActionType.changePasswordFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.changePasswordSuccessful = false;
      });
      break;
    case AuthenticationActionType.changePasswordSuccess:
      nextState = produce(previousState, (draftState) => {
        draftState.changePasswordSuccessful = true;
      });
      break;
    case AuthenticationActionType.profileInfoFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.profileInfo;
      });
      break;
    case AuthenticationActionType.profileInfoFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const profileInfoAction = action as ReturnType<typeof fetchProfileInfoSuccess>;
        draftState.profileInfo = profileInfoAction.payload.profileInfo;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
