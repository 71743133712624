export enum NotificationsActionType {
  notificationClear = 'notification/clear',
  notificationSet = 'notification/set',
}
export const clearNotification = () => ({
  type: NotificationsActionType.notificationClear,
});

export const setNotification = (errorCode: number, productionRunId: string) => ({
  type: NotificationsActionType.notificationSet,
  payload: { errorCode, productionRunId },
});
