import { Paper } from '@mui/material';

import { GiveAwayChartInfoComponent } from '../../../components/give-away-chart/give-away-chart-info.component';
import { ContainerInside } from '../../../components/structure';
import { ProductionRun } from '../../../model';

export interface OwnProps {
  productionRun: ProductionRun;
}

export const FertigPackVGiveAwayComponent = (props: OwnProps) => {
  const { productionRun } = props;

  return (
    <Paper sx={{ position: 'relative' }} data-testid="fertigPackVGiveAwayComponent">
      <ContainerInside>
        <GiveAwayChartInfoComponent productionRun={productionRun} />
      </ContainerInside>
    </Paper>
  );
};
