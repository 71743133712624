import { ArrowBack } from '@mui/icons-material';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { AppRoutePath } from '../../routes/routes';

const StyledLink = {
  display: 'flex',
  alignItems: 'center',
};

export const BackToOverviewLink = () => {
  const { t } = useTranslation(['data']);

  return (
    <Link
      data-testid="backToOverviewLink"
      component={NavLink}
      to={AppRoutePath.root}
      underline="hover"
      sx={StyledLink}
    >
      <ArrowBack />
      {t('data:productionRun.backLink')}
    </Link>
  );
};
