import { Box, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { WeighingMode } from '../../../model';
import {
  checkWeightsSelector,
  hasAllRequiredCheckWeightsSelector,
  openHelpDialog,
  scalesStatusSelector,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { CheckAccordion } from '../../accordion/check-accordion.component';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { CheckContainerInside, InfoHeaderContainer } from '../../structure';
import {
  CheckMeasurementTableComponent,
  MeasurementColumn,
} from '../check-measurement-table.component';
import { CheckProps } from '../check.component';
import { CheckDescriptionComponent } from '../common/check.components';
import {
  automaticWeightMeasurement,
  checkResultRatingWeighing,
  scaleInfo,
  tareInfo,
  weightMeasurementContent,
  weightMeasurementLink,
  weightResult,
} from '../common/weight-check.components';

export interface OwnProps {
  checkProps: CheckProps;
  confirmCheck: () => void;
  executeMeasurement: (sampleIndex: number, forTare: boolean) => void;
}

export const VolumeCheckMeasurementComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'form']);
  const { checkProps, confirmCheck, executeMeasurement } = props;
  const { productionRun, checkAttribute } = checkProps;
  const { volumeDescription: description, volumeTareValue: tareValue, sampleSize } = checkAttribute;
  const automaticWeighing =
    productionRun.scaleDevice && productionRun.scaleDevice.weighingMode === WeighingMode.Automatic;
  const deviceId = productionRun.scaleDevice && productionRun.scaleDevice.deviceId;
  const scalesStatus = useSelector(scalesStatusSelector);
  const currentScaleStatus = deviceId ? scalesStatus[deviceId] : undefined;
  const isScaleUnloaded = currentScaleStatus ? currentScaleStatus.scaleUnloaded : undefined;

  const currentWeights = useSelector(checkWeightsSelector);
  const hasAllRequiredWeights = useSelector(hasAllRequiredCheckWeightsSelector);

  const handleHelpButtonClick = () => {
    dispatch(openHelpDialog());
  };

  const checkInfoHeader = () => {
    return (
      <InfoHeaderContainer data-testid={'checkInfoHeader'}>
        <CheckDescriptionComponent text={description} />
        {scaleInfo(productionRun, checkAttribute.checkAttributeType, handleHelpButtonClick)}
        {tareInfo(tareValue)}
      </InfoHeaderContainer>
    );
  };

  const netResult = (sampleIndex: number) => {
    return (
      <Box data-testid={`weighing_${sampleIndex}`}>
        {weightResult(currentWeights[`weight${sampleIndex}`])}
      </Box>
    );
  };

  const measurementLink = (sampleIndex: number) => {
    return weightMeasurementLink(sampleIndex, executeMeasurement);
  };

  const getMeasurementLink = (sampleIndex: number) => {
    return weightMeasurementContent(
      currentWeights[`weight${sampleIndex}`]?.weightDisplay,
      netResult(sampleIndex),
      measurementLink(sampleIndex)
    );
  };

  const checkResultRating = (sampleIndex: number) => {
    return checkResultRatingWeighing(sampleIndex, currentWeights[`weight${sampleIndex}`]);
  };

  const getAutomaticMeasurementText = (sampleIndex: number) => {
    const enabled =
      (sampleIndex > 0 &&
        isScaleUnloaded &&
        !!currentWeights[`weight${sampleIndex - 1}`]?.weightDisplay) ||
      (sampleIndex === 0 && isScaleUnloaded);
    return automaticWeightMeasurement(
      enabled!,
      netResult(sampleIndex),
      currentWeights[`weight${sampleIndex}`]
    );
  };

  const resultColumn: MeasurementColumn = {
    label: t('data:check.netResult'),
    content: automaticWeighing ? getAutomaticMeasurementText : getMeasurementLink,
    width: '50%',
  };

  const ratingColumn: MeasurementColumn = {
    label: t('data:check.rating'),
    content: checkResultRating,
    width: '25%',
  };

  return (
    <Paper data-testid="volumeCheckMeasurementComponent">
      <CheckAccordion
        checkAttribute={checkAttribute}
        detailsContent={checkInfoHeader()}
        productionRun={productionRun}
      />
      <CheckContainerInside>
        <CheckMeasurementTableComponent
          sampleSize={sampleSize}
          columns={[resultColumn, ratingColumn]}
          indexColumnWidth="25%"
        />
        <Box mt={irisSpacing.button.mt}>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="right"
            spacing={irisSpacing.button.space}
            flexWrap={'nowrap'}
          >
            <Grid item>
              <ConfirmButtonComponent
                handleClick={confirmCheck}
                disabled={!hasAllRequiredWeights}
              />
            </Grid>
          </Grid>
        </Box>
      </CheckContainerInside>
    </Paper>
  );
};
