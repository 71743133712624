import { Action } from 'redux';
import { fork, takeLatest } from 'redux-saga/effects';
import { call, put } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { DeviceStatus } from '../../model';

import {
  setIdentificator as setIdentificatorAction,
  getHeartbeat as getHeartbeatStatusAction,
  DevicesActionType,
  getHeartbeatSuccess,
  getHeartbeatFailure,
  setIdentificatorSuccess,
  setIdentificatorFailure,
  takeOverScale as takeOverScaleAction,
  takeOverScaleFailure,
  takeOverScaleSuccess,
  unsetStaticScaleDevice as unsetStaticScaleDeviceAction,
  unsetStaticScaleDeviceFailure,
  unsetStaticScaleDeviceSuccess,
} from './devices.actions';

const devicesUrl = 'devices';
const scalesUrl = 'scales';
const heartbeatUrl = 'heartbeat';
const setIdUrl = 'setId';
const takeoverUrl = 'takeover';
const unsetUrl = 'unset';

function* getHeartbeatStatus(action: Action) {
  const { scaleId } = (action as ReturnType<typeof getHeartbeatStatusAction>).payload;
  try {
    const response = yield* call<[boolean, string], SagaRequest<DeviceStatus>>(
      SagaRequestHelper.get,
      true,
      `${devicesUrl}/${scalesUrl}/${scaleId}/${heartbeatUrl}`
    );
    yield put(getHeartbeatSuccess(response));
  } catch (e: any) {
    yield put(getHeartbeatFailure());
  }
}

function* setIdentificator(action: Action) {
  const { productionRunId, checkExecutionId } = (
    action as ReturnType<typeof setIdentificatorAction>
  ).payload;
  try {
    const deviceStatus = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<DeviceStatus>
    >(SagaRequestHelper.put, true, `${devicesUrl}/${scalesUrl}/${productionRunId}/${setIdUrl}`, {
      body: JSON.stringify({ checkExecutionId: checkExecutionId }),
    });
    yield put(setIdentificatorSuccess(deviceStatus));
  } catch (e: any) {
    yield put(setIdentificatorFailure(e));
  }
}

function* takeOverScale(action: Action) {
  const { productionRunId, checkExecutionId } = (action as ReturnType<typeof takeOverScaleAction>)
    .payload;
  try {
    const deviceStatus = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<DeviceStatus>
    >(SagaRequestHelper.put, true, `${devicesUrl}/${scalesUrl}/${productionRunId}/${takeoverUrl}`, {
      body: JSON.stringify({ checkExecutionId: checkExecutionId }),
    });
    yield put(takeOverScaleSuccess(deviceStatus));
  } catch (e: any) {
    yield put(takeOverScaleFailure(e));
  }
}

function* unsetStaticScaleDevice(action: Action) {
  const { productionRunId } = (action as ReturnType<typeof unsetStaticScaleDeviceAction>).payload;
  try {
    yield* call<[boolean, string], SagaRequest<DeviceStatus>>(
      SagaRequestHelper.put,
      true,
      `${devicesUrl}/${scalesUrl}/${productionRunId}/${unsetUrl}`
    );
    yield put(unsetStaticScaleDeviceSuccess());
  } catch (e: any) {
    yield put(unsetStaticScaleDeviceFailure());
  }
}

export function* getHeartbeatSaga() {
  yield takeLatest(DevicesActionType.scaleGetHeartbeat, getHeartbeatStatus);
}

export function* setIdentificatorSaga() {
  yield takeLatest(DevicesActionType.scaleSetIdentificator, setIdentificator);
}

export function* takeOverScaleSaga() {
  yield takeLatest(DevicesActionType.takeOverScale, takeOverScale);
}

export function* unsetStaticScaleDeviceSaga() {
  yield takeLatest(DevicesActionType.unsetStaticScaleDevice, unsetStaticScaleDevice);
}

export function* devicesSaga() {
  yield fork(getHeartbeatSaga);
  yield fork(setIdentificatorSaga);
  yield fork(takeOverScaleSaga);
  yield fork(unsetStaticScaleDeviceSaga);
}
