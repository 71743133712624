import { ExpandMore, RuleOutlined } from '@mui/icons-material';
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
  Accordion,
  Box,
  Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TareIcon from '../../assets/img/tara-icon.svg';
import { hasActiveTestRunAfterStart } from '../../helper';
import {
  CheckAttribute,
  CheckAttributeType,
  ProductionRun,
  ProductionRunStatus,
} from '../../model';
import { irisCustomColors, irisSpacing } from '../../theme';
import {
  SampleBannerComponent,
  SampleBannerWidth,
  OwnProps as SampleBannerProps,
} from '../sample-banner/sample-banner.component';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': { margin: 0 },
  '&.MuiPaper-root': { boxShadow: 'none' },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(irisSpacing.container.p),
  flexDirection: 'row-reverse',
  '.MuiAccordionSummary-content': {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    margin: 0,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

const AccordionSummaryContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(irisSpacing.accordion.title.pl),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(irisSpacing.info.space),
  overflow: 'auto',
}));

const StyledAccordionTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StyledAccordionTitleProps>(({ theme, styleProps }) => ({
  width: styleProps.isSampleBannerRendered ? `calc(100% - ${SampleBannerWidth}px)` : undefined,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  gridColumn: 1,
  '&.MuiTypography-h3': { margin: 0 },
  '.MuiAccordionSummary-content.Mui-expanded &': {
    whiteSpace: 'break-spaces',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(irisSpacing.container.p),
  paddingTop: 0,
}));

interface StyledAccordionTitleProps {
  styleProps: { isSampleBannerRendered: boolean };
}

export interface OwnProps {
  detailsContent: JSX.Element;
  checkAttribute: CheckAttribute;
  productionRun: ProductionRun;
}

export const CheckAccordion = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const { detailsContent, checkAttribute, productionRun } = props;
  const { sampleSize, name: checkName } = checkAttribute;
  const isFinishing = productionRun?.status === ProductionRunStatus.Finishing;
  const isTestRunning = checkAttribute.checkAttributeType === CheckAttributeType.TestRun;
  const isTareDetermining = checkAttribute.checkAttributeType === CheckAttributeType.Tare;
  const isCustomized = isTestRunning || isTareDetermining;
  const isSampleBannerRendered = isCustomized || sampleSize > 1;

  const customSampleBannerText = () => {
    return isTestRunning
      ? t('data:check.testRunCaps')
      : isTareDetermining
      ? t('data:check.tareCaps')
      : undefined;
  };

  const getCustomSampleBannerProps = (): SampleBannerProps | undefined => {
    return isCustomized
      ? {
          sampleSize: 0,
          customText: customSampleBannerText(),
          customBackgroundColor: irisCustomColors.irisRed,
          customTextColor: irisCustomColors.irisWhite,
        }
      : undefined;
  };

  const getCustomIcon = () => {
    return isCustomized ? (
      <Avatar sx={{ backgroundColor: irisCustomColors.irisGold, height: 35, width: 35 }}>
        {isTestRunning ? (
          <RuleOutlined sx={{ color: irisCustomColors.irisWhite, fontSize: 24 }} />
        ) : isTareDetermining ? (
          <img
            src={TareIcon}
            alt={''}
            style={{ color: irisCustomColors.irisWhite, overflow: 'auto' }}
          />
        ) : undefined}
      </Avatar>
    ) : undefined;
  };

  const getCustomTitle = () => {
    return isTestRunning
      ? productionRun && hasActiveTestRunAfterStart(productionRun)
        ? t('data:check.testRunAfterStart')
        : t('data:check.testRunAfterDisruption')
      : isTareDetermining
      ? t('data:check.tareDeterminationAfterStart')
      : undefined;
  };

  const getSummaryContent = () => {
    return (
      <AccordionSummaryContainer>
        {getCustomIcon() && <Box data-testid="icon">{getCustomIcon()}</Box>}
        <StyledAccordionTitle
          styleProps={{ isSampleBannerRendered }}
          variant="h3"
          data-testid="currentCheckHeader"
        >
          {getCustomTitle() ??
            `${
              isFinishing ? t('data:check.finishingChecks') : t('data:check.currentCheck')
            }: ${checkName}`}
        </StyledAccordionTitle>
      </AccordionSummaryContainer>
    );
  };

  const getSampleBanner = () => {
    return isSampleBannerRendered ? (
      <SampleBannerComponent {...(getCustomSampleBannerProps() ?? { sampleSize })} />
    ) : undefined;
  };

  return (
    <Box data-testid={'checkAccordion'}>
      {getSampleBanner()}
      <StyledAccordion square>
        <StyledAccordionSummary expandIcon={<ExpandMore />} data-testid="accordionSummary">
          {getSummaryContent()}
        </StyledAccordionSummary>
        <StyledAccordionDetails data-testid="accordionDetails">
          {detailsContent}
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};
