import {
  ProductionRunCheckExecution,
  ProductionRunCheckExecutionWeighingSampleResult,
} from '../../model';

export enum WeightsActionType {
  checkWeightInit = 'checkWeights/init',

  checkWeightExecute = 'measurement/execute',
  checkWeightExecuteSuccess = 'measurement/execute/success',
  checkWeightExecuteFailure = 'measurement/execute/failure',

  checkWeightSamplesFetch = 'measurements/fetchAll/',
  checkWeightSamplesFetchSuccess = 'measurements/fetchAll/success',
  checkWeightSamplesFetchFailure = 'measurements/fetchAll/failure',

  checkWeightStateReset = 'checkWeightState/reset',
}

export const initCheckWeights = (sampleSize: number) => ({
  type: WeightsActionType.checkWeightInit,
  payload: { sampleSize },
});

export const executeCheckWeight = (
  sampleNumber: number,
  productionRunId: string,
  executionId: string,
  scaleId: string,
  isTareWeighing: boolean
) => ({
  type: WeightsActionType.checkWeightExecute,
  payload: { sampleNumber, productionRunId, executionId, scaleId, isTareWeighing },
});

export const executeCheckWeightSuccess = (
  sampleNumber: number,
  weight: ProductionRunCheckExecutionWeighingSampleResult,
  isTaring: boolean
) => ({
  type: WeightsActionType.checkWeightExecuteSuccess,
  payload: { sampleNumber, weight, isTaring },
});

export const executeCheckWeightFailure = (e: any) => ({
  type: WeightsActionType.checkWeightExecuteFailure,
  payload: { e },
});

export const fetchCheckWeightSamples = (productionRunId: string, executionId: string) => ({
  type: WeightsActionType.checkWeightSamplesFetch,
  payload: { productionRunId, executionId },
});

export const fetchCheckWeightSamplesSuccess = (checkExecution: ProductionRunCheckExecution) => ({
  type: WeightsActionType.checkWeightSamplesFetchSuccess,
  payload: { checkExecution },
});

export const fetchCheckWeightSamplesFailure = () => ({
  type: WeightsActionType.checkWeightSamplesFetchFailure,
});

export const resetCheckWeightState = () => ({
  type: WeightsActionType.checkWeightStateReset,
});
