import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Typography, styled, Box, Divider } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CheckAttributeType, ProductionRun, TareMode, WeighingMode } from '../../../model';
import { closeHelpDialog } from '../../../store';
import { irisCustomColors } from '../../../theme';

const PREFIX = 'HelpInfoComponent';

const classes = {
  header: `${PREFIX}-header`,
  help: `${PREFIX}-help`,
  stepSpacing: `${PREFIX}-stepSpacing`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.help}`]: {
    marginLeft: theme.spacing(2),
    marginBottom: 0,
  },
  [`& .${classes.stepSpacing}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  checkAttributeType: CheckAttributeType;
}

export const HelpInfoComponent = (props: OwnProps) => {
  const { productionRun, checkAttributeType } = props;
  const isIndividuallyTaring =
    productionRun.article.tareMode === TareMode.IndividualTare &&
    checkAttributeType === CheckAttributeType.FertigPackV;
  const isAutomaticWeighing = productionRun.scaleDevice?.weighingMode === WeighingMode.Automatic;

  const { t } = useTranslation(['data', 'common']);
  const dispatch = useDispatch();

  const handleCloseHelpDialog = () => {
    dispatch(closeHelpDialog());
  };

  const helpHeader = () => {
    return (
      <Box className={classes.header}>
        <HelpOutlineIcon fontSize="large" sx={{ color: irisCustomColors.irisGreen }} />
        <Typography variant="h3" className={classes.help}>
          <b>{t('data:check.helpPopover.help')}</b>
        </Typography>
      </Box>
    );
  };

  const closeButton = () => {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleCloseHelpDialog}
          data-testid="closeHelpDialogBtn"
        >
          {t('common:close')}
        </Button>
      </Box>
    );
  };

  const helpTextIndividuallyTaring = () => {
    return (
      <Box data-testid="helpTextIndividuallyTaring">
        <Typography className={classes.stepSpacing}>
          {t('data:check.helpPopover.individualTare.actionOrder')}
        </Typography>
        <Typography>
          <b>{t('data:check.helpPopover.individualTare.numbering')}</b>
        </Typography>
        <Typography className={classes.stepSpacing}>
          {t('data:check.helpPopover.individualTare.numberingDescription')}
        </Typography>
        <Typography>
          <b>{t('data:check.helpPopover.individualTare.getTare')}</b>
        </Typography>
        <Typography className={classes.stepSpacing}>
          {t('data:check.helpPopover.individualTare.getTareDescription')}
        </Typography>
        <Typography>
          <b>{t('data:check.helpPopover.individualTare.fillBundle')}</b>
        </Typography>
        <Typography className={classes.stepSpacing}>
          {t('data:check.helpPopover.individualTare.fillBundleDescription')}
        </Typography>
        <Typography>
          <b>{t('data:check.helpPopover.individualTare.executeCheck')}</b>
        </Typography>
        <Typography>
          {t('data:check.helpPopover.individualTare.executeCheckDescription')}
        </Typography>
      </Box>
    );
  };

  const helpTextAutomaticWeighing = () => {
    return (
      <Box data-testid="helpTextAutomaticWeighing">
        <Typography className={classes.stepSpacing}>
          <b>{t('data:check.helpPopover.automaticWeighing.actionOrder')}</b>
        </Typography>
        <ol>
          <li>{t('data:check.helpPopover.automaticWeighing.place')}</li>
          <li>{t('data:check.helpPopover.automaticWeighing.wait')}</li>
          <li>{t('data:check.helpPopover.automaticWeighing.check')}</li>
          <li>{t('data:check.helpPopover.automaticWeighing.remove')}</li>
          <li>{t('data:check.helpPopover.automaticWeighing.repeat')}</li>
        </ol>
      </Box>
    );
  };

  return (
    <Root data-testid="helpInfo">
      {helpHeader()}
      {isIndividuallyTaring && helpTextIndividuallyTaring()}
      {isIndividuallyTaring && isAutomaticWeighing && (
        <Divider sx={{ my: 3 }} data-testid="helpTextDivider" />
      )}
      {isAutomaticWeighing && helpTextAutomaticWeighing()}
      {closeButton()}
    </Root>
  );
};
