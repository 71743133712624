import { Grid } from '@mui/material';

import { filterIssues } from '../../helper';
import { IssueCode, ProductionRunIssue } from '../../model';
import { getPixels, irisSpacing } from '../../theme';
import { InfoboxComponent, mapToInfoboxType } from '../infobox/infobox.component';
import { ContainerOutside, StickyContainerOutside } from '../structure';

import { IssueComponent } from './issue.component';

export enum IssuesElementStyle {
  plain = 1,
  infobox,
  sticky,
}

interface OwnProps {
  issues: ProductionRunIssue[];
  allowedIssueCodes?: IssueCode[];
  iconSize?: 'small' | 'inherit' | 'medium' | 'large';
  elementStyle?: IssuesElementStyle;
}

export const IssuesComponent = (props: OwnProps) => {
  const { issues, allowedIssueCodes, iconSize, elementStyle } = props;
  const filteredIssues = filterIssues(issues, allowedIssueCodes);

  const getInfoboxIssue = (issue: ProductionRunIssue) => {
    return (
      <ContainerOutside key={`${issue.issueType}_${issue.issueCode}`}>
        <InfoboxComponent
          type={mapToInfoboxType(issue.issueType)}
          customElement={<IssueComponent issue={issue} iconSize={iconSize} />}
        />
      </ContainerOutside>
    );
  };

  const getStickyInfoboxIssue = (issue: ProductionRunIssue, index: number) => {
    let top = irisSpacing.anchor.space;
    if (index > 0) {
      top += irisSpacing.anchor.space * (index - 1) + getPixels(irisSpacing.maxInfobox.space);
    }
    return (
      <StickyContainerOutside
        key={`${issue.issueType}_${issue.issueCode}`}
        styleProps={{ top: top }}
        data-testid="stickyContainerOutside"
      >
        <InfoboxComponent
          type={mapToInfoboxType(issue.issueType)}
          customElement={<IssueComponent issue={issue} iconSize={iconSize} />}
        />
      </StickyContainerOutside>
    );
  };

  const getPlainIssue = (issue: ProductionRunIssue) => {
    return (
      <Grid item key={`${issue.issueType}_${issue.issueCode}`}>
        <IssueComponent issue={issue} iconSize={iconSize} />
      </Grid>
    );
  };

  if (filteredIssues.length === 0) return <></>;
  switch (elementStyle) {
    case IssuesElementStyle.infobox:
      return <>{filteredIssues.map((issue) => getInfoboxIssue(issue))}</>;
    case IssuesElementStyle.sticky:
      return (
        <>
          {filteredIssues.map((issue) =>
            getStickyInfoboxIssue(issue, filteredIssues.indexOf(issue))
          )}
        </>
      );
    default:
      return (
        <Grid container spacing={1} flexDirection={'column'}>
          {filteredIssues.map((issue) => getPlainIssue(issue))}
        </Grid>
      );
  }
};
