import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { irisCustomColors, irisSpacing } from '../../../theme';
import { ConfirmButtonComponent } from '../../buttons/confirm-button.component';
import { ResponsiveImageComponent } from '../../image/responsive-image/responsive-image.component';

export const CheckNarrowContainer = styled('div')(({ theme }) => ({
  margin: 'auto',
  width: '60%',
}));

export const CheckInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: theme.spacing(2),
}));

interface CheckDescriptionProps {
  text?: string;
}

export const CheckDescriptionComponent = (props: CheckDescriptionProps) => {
  const { t } = useTranslation(['data']);
  const { text } = props;

  return text && text.length > 0 ? (
    <Typography>{text}</Typography>
  ) : (
    <Typography fontStyle={'italic'} color={irisCustomColors.irisGrayDimmed}>
      {t('data:checkAttribute.missingDescription')}
    </Typography>
  );
};

interface CheckImageProps {
  imageUrl?: string;
}

export const CheckImageComponent = (props: CheckImageProps) => {
  const { imageUrl } = props;
  const { t } = useTranslation(['data']);

  return (
    <CheckNarrowContainer>
      <ResponsiveImageComponent imageUrl={imageUrl} altText={t('data:checkAttribute.image')} />
    </CheckNarrowContainer>
  );
};

interface CheckConfirmationButtonProps {
  handleClick: () => void;
  disabled?: boolean;
}

export const CheckConfirmationButtonComponent = (props: CheckConfirmationButtonProps) => {
  const { handleClick, disabled } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="right"
      alignItems="center"
      spacing={irisSpacing.button.space}
    >
      <Grid item>
        <ConfirmButtonComponent handleClick={handleClick} disabled={disabled} />
      </Grid>
    </Grid>
  );
};
