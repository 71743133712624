export enum InternalErrorCode {
  ProductionRunAlreadyStarted = 100,
  ProductionInvalidStateChange = 101,
  ProductionRunDoneException = 102,
  ProductionOrderNotStartable = 103,
  ProductionRunCheckExecutionAlreadyDone = 104,
  ProductionRunCheckExecutionSampleSizeMismatch = 105,
  ProductionLineStaticScaleDeviceAlreadySet = 106,
  StaticScaleCommunicationFailed = 107,
  UsernameAlreadyUsed = 108,
  CurrentPasswordIncorrect = 109,
  LicenseReadFailed = 110,
  LicenseInvalid = 111,
  LicenseInsufficient = 112,
  LicenseExpired = 113,
  ProductionRunCheckExecutionSampleAlreadyDoneException = 114,
  ProductionRunCheckExecutionTareValueNotInRange = 120,
  ScaleTakeOverFailure = 121,
  ProductionRunCheckExecutionInvalidWeighingbridge = 124,
  ProductionRunCheckExecutionAutoWeightException = 125,
  ProductionRunCompetingOrderException = 126,
}

export enum ScaleErrorCode {
  TareOrSetNullError = 15,
  StaticScaleNotUnloaded = 980,
}
