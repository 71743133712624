import WarningIcon from '@mui/icons-material/Warning';
import { Button, Typography, styled } from '@mui/material';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AppRoutePath } from '../../routes/routes';

export interface OwnProps {
  tu1percentage: number | undefined;
}

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(4),
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  marginRight: theme.spacing(2),
}));

export const TU1ViolationInfoComponent = (props: OwnProps) => {
  const { tu1percentage } = props;
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();

  const goToOverview = () => {
    dispatch(push(`${AppRoutePath.root}`));
  };

  return (
    <>
      <Header>
        <StyledWarningIcon fontSize="large" />
        <Typography variant="h4">{t('data:check.tu1Violation')}</Typography>
      </Header>
      <Typography data-testid="tu1Percentage">
        {`${
          tu1percentage
            ? t('data:check.tu1CurrentValue', {
                percentage: tu1percentage,
              })
            : t('data:check.tu1CurrentValueFallback')
        } `}
        <b>{`${t('data:check.chargeWarning')} `}</b>
        {`${t('data:check.informSupervisorViolation')} `}
      </Typography>
      <ButtonContainer>
        <Button variant="contained" onClick={goToOverview} data-testid="goToOverviewBtn">
          {t('data:check.goToOverview')}
        </Button>
      </ButtonContainer>
    </>
  );
};
