import { Dialog, DialogContent, Theme, styled, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

import { ProductionRun } from '../../model';
import { appendChecksDialogOpenSelector } from '../../store';
import { irisSpacing, popoverBreakpoint, responsiveness } from '../../theme';

import { AppendInfoComponent } from './append-info.component';

interface StylePropsWrapper {
  styleProps: {
    smallScreen: boolean;
  };
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  '& .MuiDialog-paper': {
    margin: styleProps.smallScreen ? 0 : responsiveness.page.padding.x,
    marginLeft: styleProps.smallScreen ? 0 : '58px',
    marginRight: styleProps.smallScreen ? 0 : '58px',
    minWidth: responsiveness.popover.width.min,
    maxWidth: responsiveness.popover.width.max,
    minHeight: responsiveness.popover.height.min,
    maxHeight: responsiveness.popover.height.max,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(irisSpacing.container.p),
  },
  '& .MuiDialog-container': {
    margin: 'auto',
    padding: responsiveness.page.padding.x,
    position: styleProps.smallScreen ? 'absolute' : 'relative',
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
}

export const AppendDialogComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const isOpen = useSelector(appendChecksDialogOpenSelector);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(popoverBreakpoint));

  return (
    <StyledDialog
      styleProps={{ smallScreen }}
      open={isOpen}
      maxWidth="md"
      data-testid="appendDialog"
      fullWidth
    >
      <DialogContent data-testid="appendDialogContent">
        <AppendInfoComponent productionRun={productionRun} />
      </DialogContent>
    </StyledDialog>
  );
};
