import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { irisSpacing } from '../../theme';

interface StylePropsWrapper {
  styleProps: {
    top: number;
  };
}

// For standalone containers like tables or papers (without outside headers)
export const ContainerOutside = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(irisSpacing.container.space),
}));

export const StickyContainerOutside = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  marginTop: theme.spacing(irisSpacing.container.space),
  position: 'sticky',
  zIndex: 10,
  top: styleProps.top,
}));

// For containers not needing a top margin (usually having outside headers)
export const ContainerOutsideWithHeader = styled('div')(({ theme }) => ({
  marginTop: 0,
}));

export const ContainerInside = styled('div')(({ theme }) => ({
  padding: theme.spacing(irisSpacing.container.p),
}));

export const CheckContainerInside = styled(ContainerInside)(({ theme }) => ({
  paddingTop: 0,
}));
