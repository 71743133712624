import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { canBePaused, isRunPausedManually } from '../../helper';
import { ProductionRun } from '../../model';
import { pauseProductionRun } from '../../store';
import { irisSpacing } from '../../theme';
import { dateAndTimeOptions } from '../../utils/date-utils';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';
import { ResponsiveImageComponent } from '../image/responsive-image/responsive-image.component';
import { ContainerOutside } from '../structure';
import { DescriptionTableComponent } from '../table/description-table.component';

import { ProductionRunButtonsComponent } from './production-run-buttons.component';

const ButtonColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
}));

const ButtonRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
  flexWrap: 'wrap',
  gap: theme.spacing(irisSpacing.button.space),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(irisSpacing.container.p),
  flexDirection: 'row-reverse',
  '.MuiAccordionSummary-content': {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    margin: 0,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(irisSpacing.accordion.title.pl),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  gridColumn: 1,
  '&.MuiTypography-h3': { margin: 0 },
  '.MuiAccordionSummary-content.Mui-expanded > &': {
    whiteSpace: 'break-spaces',
  },
}));

const PauseLinkContainer = styled(Box)(({ theme }) => ({
  gridColumn: 3,
  display: 'flex',
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(irisSpacing.container.p),
  paddingTop: 0,
}));

const getProductionRunDescription = (productionRun: ProductionRun, t: TFunction, language: any) => {
  const startDate = new Date(productionRun.started);

  return [
    {
      rowName: t('data:productionRun.startTime'),
      rowValue: `${startDate.toLocaleString(language, dateAndTimeOptions)} ${t('common:time')}`,
    },
    {
      rowName: t('data:productionOrder.amount'),
      rowValue: productionRun.amount,
    },
    {
      rowName: t('data:article.article'),
      rowValue: `${productionRun.article.articleNumber} | ${productionRun.article.name} ${
        productionRun.article.nameSuffix ? ' | ' + productionRun.article.nameSuffix : ''
      }`,
    },
    {
      rowName: t('data:productionOrder.batchNumber'),
      rowValue: productionRun.batchNumber,
    },
  ];
};

export interface OwnProps {
  productionRun: ProductionRun;
  stopProductionRun?: () => void;
  appendChecks?: () => void;
  expanded?: boolean;
}

export const ProductionRunComponent = (props: OwnProps) => {
  const { t, i18n } = useTranslation(['data']);
  const dispatch = useDispatch();
  const { productionRun, stopProductionRun, appendChecks, expanded } = props;
  const [isExpanded, setIsExpanded] = useState(!!expanded);

  const pauseAllowed = canBePaused(productionRun);
  const paused = isRunPausedManually(productionRun);

  const hasButtons = (!paused && pauseAllowed) || !!appendChecks || !!stopProductionRun;

  const pauseRun = (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    dispatch(pauseProductionRun(productionRun.id));
  };

  const onAccordionChange = (expanded: boolean) => {
    setIsExpanded(expanded);
  };

  return (
    <ContainerOutside data-testid="orderAccordion">
      <Accordion
        square
        defaultExpanded={expanded}
        onChange={(_, expanded) => onAccordionChange(expanded)}
      >
        <StyledAccordionSummary expandIcon={<ExpandMore />} data-testid="accordionSummary">
          <StyledAccordionTitle variant="h3" data-testid="summaryHeadline">
            {t('data:productionRun.details')}: {productionRun.orderNumber}
          </StyledAccordionTitle>
          {!paused && pauseAllowed && !isExpanded && (
            <PauseLinkContainer>
              <Link
                component="button"
                variant="body2"
                underline="always"
                color="secondary"
                fontWeight="medium"
                onClick={(event) => pauseRun(event)}
                data-testid={'pauseBtn'}
                whiteSpace={'nowrap'}
              >
                {t('data:productionRun.pauseProductionRun')}
              </Link>
            </PauseLinkContainer>
          )}
        </StyledAccordionSummary>
        <StyledAccordionDetails data-testid="accordionDetails">
          <GridContainer level={GridLevel.InfoPaper}>
            <GridItem s={4}>
              <ResponsiveImageComponent
                imageUrl={productionRun.article.imageUrl}
                altText={t('data:article.image')}
              />
            </GridItem>
            <GridItem s={8} l={4}>
              <Box>
                <Box>
                  <DescriptionTableComponent
                    tableData={getProductionRunDescription(productionRun, t, i18n.language)}
                  />
                </Box>
              </Box>
            </GridItem>
            {!productionRun.disrupted && hasButtons && isExpanded && (
              <GridItem l={4}>
                <ButtonColumn>
                  <ButtonRow>
                    <ProductionRunButtonsComponent
                      productionRun={productionRun}
                      stopProductionRun={stopProductionRun}
                      appendChecks={appendChecks}
                    />
                  </ButtonRow>
                </ButtonColumn>
              </GridItem>
            )}
          </GridContainer>
        </StyledAccordionDetails>
      </Accordion>
    </ContainerOutside>
  );
};
