import { produce } from 'immer';
import { Action } from 'redux';

import { DeviceStatus } from '../../model';

import {
  DevicesActionType,
  getHeartbeatSuccess,
  takeOverScaleSuccess,
  setIdentificatorSuccess,
} from './devices.actions';
import {
  DevicesWebsocketActionType,
  changedStaticScaleDeviceStatus,
} from './devices.websocket-actions';

export interface DevicesState {
  scalesStatus: { [guid: string]: DeviceStatus };
}

export const getInitialState = (): DevicesState => {
  return {
    scalesStatus: {},
  };
};

const updateScales = (draftState: DevicesState, deviceStatus: DeviceStatus) => {
  draftState.scalesStatus[deviceStatus.id] = deviceStatus;
};

export const devicesReducer = (
  previousState: DevicesState = getInitialState(),
  action: Action<DevicesActionType | DevicesWebsocketActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case DevicesActionType.scaleGetHeartbeatSuccess:
    case DevicesWebsocketActionType.deviceStatusChanged:
    case DevicesActionType.takeOverScaleSuccess:
      nextState = produce(previousState, (draftState) => {
        const deviceStatusChangedAction = action as ReturnType<
          | typeof getHeartbeatSuccess
          | typeof changedStaticScaleDeviceStatus
          | typeof takeOverScaleSuccess
        >;
        const deviceStatus = deviceStatusChangedAction.payload?.deviceStatus;
        deviceStatus && updateScales(draftState, deviceStatus);
      });
      break;

    case DevicesActionType.scaleSetIdentificatorSuccess:
      const setIdentificatorSuccessAction = action as ReturnType<typeof setIdentificatorSuccess>;
      nextState = produce(previousState, (draftState) => {
        const deviceStatus = setIdentificatorSuccessAction.payload.deviceStatus;
        updateScales(draftState, deviceStatus);
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
